// libs
import { bindActionCreators } from 'redux';

// actions
import {
    authenticateUser,
    getUser,
    updateUser,
    createUser,
    deleteUser,
} from './user/index';
import { getUsers } from './users/index';
import {
    getOrders,
    getOrder,
    updateOrder,
    createOrder,
    checkOrders,
} from './order/index';
import {
    getApplications,
    updateApplication,
    createApplication,
    getApplication,
} from './application/index';
import { sendEmail } from './email/index';
import { sendSms } from './sms/index';
import {
    login,
    logout,
    resetPassword,
    googleLogin,
} from './authentication/index';
import {
    updateOrderStatus,
    updateBidStatus,
    updateTaskStatus,
} from './status/index';
import { updateRange } from './range/index';
import { getImage, updateImage } from './image/index';
import {
    createSubscription,
    getSubscription,
    setSubscription,
    deleteSubscription,
} from './subscription/index';
import { createBid, getBids, getBid, updateBid, checkBids } from './bid/index';
import {
    createTemplate,
    getTemplates,
    updateTemplate,
    deleteTemplate,
} from './template/index';
import { createRule, getRules, updateRule, deleteRule } from './rule/index';
import { createItem, getItems, updateItem, deleteItem } from './item/index';
import {
    getPlantSelection,
    updatePlantSelection,
} from '../actions/plantSelection/index';
import { getUnread } from './message/index';
import { getTasks, getTask, updateTask } from './task/index';
import { getChangeOrders } from './changeOrder/index';
import { createAnswer, getAnswers, updateAnswers } from './answer/index';
import { navigate } from './navigation/index';
import { getReferrals } from './referral/index';
import { toggleLoading } from './loading/index';
import { getQRCodes } from './qrCode/index';
import { getScans } from './scan/index';
import { getProducts } from './product/index';
import { getAccount, updateAccount } from './account/index';
import { getShapes } from './shape/index';
import { getBeds, updateBed, createBed } from './bed/index';
import { getGeolocation, getCounty, getServiceArea } from './location/index';
import { getReviews, createReview } from './reviews/index';
import { sendAlert } from './alert/index';
import { getPlantCommonTypes } from './plantCommonType/index';
import { createDirectDeposit } from './payout/index';
import { getQuestions } from './question/index';
import { getReportType } from './reportType/index';
import { createReport, getReports, getReport } from './report/index';
import { createReschedule, getReschedules } from './reschedule/index';
import { getConversations, createConversation } from './conversation/index';
import { createMessage } from './message/index';
import { createPlantActivity } from './plantActivity/index';
import {
    getSpecialRequest,
    createSpecialRequest,
} from './specialRequest/index';
import { getDrafts, createDraft, updateDraft } from './draft/index';
import {
    createHarvestSchedule,
    getHarvestSchedule,
    updateHarvestSchedule,
} from './harvestSchedule/index';
import {
    chargeCard,
    createCustomer,
    createCard,
    deleteCard,
} from './payment/index';

export const getIndicatorStyles = (order) => {
    let orderTypeIndicator = 'bg-white';
    if (order.type === 'yard assessment') {
        orderTypeIndicator = 'bg-blue1';
    } else if (order.type === 'installation' || order.type === 'revive') {
        orderTypeIndicator = 'bg-orange1';
    } else if (order.type === 'full plan' || order.type === 'assisted plan') {
        orderTypeIndicator = 'bg-green1';
    } else if (order.type === 'crop rotation') {
        orderTypeIndicator = 'bg-purpleB90';
    } else if (order.type === 'misc') {
        orderTypeIndicator = 'bg-gray3';
    } else if (order.type === 'initial planting') {
        orderTypeIndicator = 'bg-yellow1';
    }

    return orderTypeIndicator;
};

export function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            authenticateUser,
            getUser,
            updateUser,
            createUser,
            deleteUser,
            getUsers,
            getOrders,
            getOrder,
            updateOrder,
            createOrder,
            checkOrders,
            getApplications,
            updateApplication,
            createApplication,
            getApplication,
            sendEmail,
            sendSms,
            login,
            logout,
            resetPassword,
            googleLogin,
            updateOrderStatus,
            updateBidStatus,
            updateRange,
            getImage,
            updateImage,
            createSubscription,
            getSubscription,
            setSubscription,
            deleteSubscription,
            createBid,
            getBids,
            getBid,
            updateBid,
            checkBids,
            createTemplate,
            getTemplates,
            updateTemplate,
            deleteTemplate,
            createRule,
            getRules,
            updateRule,
            deleteRule,
            createItem,
            getItems,
            updateItem,
            deleteItem,
            getUnread,
            updateTaskStatus,
            getTask,
            getTasks,
            updateTask,
            getChangeOrders,
            getAnswers,
            createAnswer,
            updateAnswers,
            navigate,
            getReferrals,
            toggleLoading,
            getQRCodes,
            getScans,
            getProducts,
            getAccount,
            updateAccount,
            getShapes,
            createBed,
            getBeds,
            updateBed,
            getPlantSelection,
            updatePlantSelection,
            getGeolocation,
            getCounty,
            getServiceArea,
            sendAlert,
            getReviews,
            createReview,
            getPlantCommonTypes,
            createDirectDeposit,
            getQuestions,
            getReportType,
            createReport,
            getReports,
            getReport,
            createReschedule,
            getReschedules,
            getConversations,
            createConversation,
            createMessage,
            createPlantActivity,
            getSpecialRequest,
            createSpecialRequest,
            getDrafts,
            createDraft,
            updateDraft,
            createHarvestSchedule,
            getHarvestSchedule,
            updateHarvestSchedule,
            chargeCard,
            createCustomer,
            createCard,
            deleteCard,
        },
        dispatch,
    );
}
