// libraries
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import moment from 'moment';

// UI components
import { Divider } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Icon from '@material-ui/core/Icon';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import StepIndicatorV2 from './StepIndicatorV2';
import Alert from '../components/Alert.js';

// actions
import { updateUser } from '../actions/user/index';
import { createSubscription } from '../actions/subscription/index';
import { createOrder, updateOrder } from '../actions/order/index';
import { getImage, updateImage } from '../actions/image/index';
import { sendEmail } from '../actions/email/index';
import { sendSms } from '../actions/sms/index';
import { getTemplate } from '../actions/template/index';
import { createBid } from '../actions/bid/index';
import { chargeCard } from '../actions/payment/index';
import { getRules } from '../actions/rule/index';
import { createJob } from '../actions/job/index';
import { createReport } from '../actions/report/index';
import { getReportType } from '../actions/reportType/index';
import { getQuestions } from '../actions/question/index';
import { getQuestionTypes } from '../actions/questionType/index';
import { toggleLoading } from '../actions/loading/index';
import { createTask } from '../actions/task/index';
import { getPlans } from '../actions/plan/index';
import { getProducts } from '../actions/product/index';
import { getAccount } from '../actions/account/index';
import { getIrrigation } from '../actions/irrigation/index';
import { getDrafts, createDraft } from '../actions/draft/index';
import { getBeds } from '../actions/bed/index';
import { createPlantList } from '../actions/plantList/index';
import {
    getSpecialRequest,
    createSpecialRequest,
} from '../actions/specialRequest';

// formatting
import { setQty } from '../formatting/setQty.js';
import dimensions from '../formatting/dimensions';

// helpers
import { applyGardenRules } from '../helpers/applyGardenRules.js';
import calculateTotalFeet from '../helpers/calculateTotalFeet';
import validateBeds from '../helpers/validateBeds';
import getQuotePricing from '../helpers/getQuotePricing.js';
import getHighestKey from '../helpers/getHighestKey';
import generatePlotPoints from '../helpers/generatePlotPoints';

// vars
import vars from '../vars/index';
import {
    FULL_PLAN,
    INSTALLATION,
    REVIVE,
    INITIAL_PLANTING,
    YARD_ASSESSMENT,
    SOIL_REFILL,
    MISC,
} from '../vars/types';

// styling
import '../css/upload-modal.css';

class UploadModal extends Component {
    state = {
        activeStep: 1,
        isOpen: false,
        orderImagesList: [],
        receiptsList: [],
        processedImages: [],
        processedReceipts: [],
        fivePotGarden: 0,
        trellis: 0,
        gardenCage: 0,
        coverBox: 0,
        cloche: 0,
        trellisIsSelected: false,
        gardenCageIsSelected: false,
        coverBoxIsSelected: false,
        clocheIsSelected: false,
        setReminder: false,
        gardenInstallation: false,
        gardenRevive: false,
        otherService: false,
        materialsPurchased: false,
        shapes: [],
        newBeds: [
            {
                shape: 'rectangle',
                height: 12,
            },
        ],
        currentBeds: [
            {
                shape: 'rectangle',
                height: 12,
            },
        ],
        otherServices: [
            {
                description: '',
            },
        ],
        deadPlantList: [
            {
                name: '',
                qty: 0,
            },
        ],
        harvestedPlantList: [
            {
                name: '',
                qty: 0,
            },
        ],
        newPlantList: [
            {
                name: '',
                qty: 0,
            },
        ],
        plantReplacementList: [
            {
                name: '',
                qty: 0,
            },
        ],
        wateringScheduleDays: 'daily',
    };

    async componentDidUpdate(prevProps) {
        if (prevProps.error !== this.props.error) {
            this.setState({
                alertIsOpen: true,
            });
        }

        if (prevProps.isOpen !== this.props.isOpen) {
            if (this.props.isOpen) {
                // if order type is yard assessment {...}
                if (this.props.order.type === YARD_ASSESSMENT) {
                    // get rules
                    await this.props.getRules();

                    // get products
                    await this.props.getProducts();

                    // get irrigation
                    await this.props.getIrrigation();

                    // set garden bed product
                    const gardenBedProduct = this.props.products.data.find(
                        (product) => product.name === 'garden bed',
                    );

                    // get installation template
                    const installationTemplate = await this.props.getTemplate(
                        `name=${INSTALLATION}`,
                    );

                    // update UI
                    this.setState({
                        gardenBedProduct,
                        installationTemplate: installationTemplate.data,
                    });
                } else if (
                    this.props.order.type === INSTALLATION ||
                    this.props.order.type === REVIVE
                ) {
                    // get drafts
                    await this.props.getDrafts(
                        `customer=${this.props.order.customer._id}&published=${false}`,
                    );
                }
            }
        }
    }

    async next() {
        // validate inputs
        const inputsValidated = this.validateInputs();

        // if inputs are valid {...}
        if (inputsValidated) {
            // update UI
            this.setState({ activeStep: this.state.activeStep + 1 });
        }
    }

    back() {
        // if step 1, close upload modal
        if (this.state.activeStep === 1) return this.props.onClose();

        // update UI
        this.setState({
            activeStep: this.state.activeStep - 1,
        });
    }

    validateInputs() {
        // set initial validation
        let valid = true;
        const install = this.state.gardenInstallation;
        const revive = this.state.gardenRevive;
        const other = this.state.otherService;

        // get order type
        const orderType = this.props.order.type;

        // if order is yard assessment {...}
        if (orderType === YARD_ASSESSMENT) {
            if (this.state.activeStep === 1) {
                if (!install && !revive && !other) {
                    window.alert('Please select an option');
                    valid = false;
                }
            } else if (this.state.activeStep === 2) {
                if ((install && revive) || (install && !revive)) {
                    valid = this.checkInstallationInputs();
                } else if (!install && revive) {
                    valid = this.checkReviveInputs();
                } else if (!install && !revive && other) {
                    valid = this.checkOtherServicesInputs();
                }
            } else if (this.state.activeStep === 3) {
                if (install) {
                    if (revive) {
                        valid = this.checkReviveInputs();
                    } else if (other) {
                        valid = this.checkOtherServicesInputs();
                    } else {
                        valid = this.checkImageInput();
                    }
                } else if (revive) {
                    if (other) {
                        valid = this.checkOtherServicesInputs();
                    } else {
                        valid = this.checkImageInput();
                    }
                } else if (other) {
                    valid = this.checkImageInput();
                }
            } else if (this.state.activeStep === 4) {
                if (install && revive && other) {
                    valid = this.checkOtherServicesInputs();
                } else if (install && !revive && !other) {
                    valid = this.checkImageInput();
                } else if (install && revive && !other) {
                    valid = this.checkImageInput();
                } else if (install && !revive && other) {
                    valid = this.checkImageInput();
                } else if (!install && revive && !other) {
                    valid = this.checkImageInput();
                } else if (!install && revive && other) {
                    valid = this.checkImageInput();
                } else if (!install && !revive && other) {
                    valid = this.checkImageInput();
                }
            } else if (this.state.activeStep === 5) {
                if (install && revive && other) {
                    valid = this.checkImageInput();
                } else if (install && revive && !other) {
                    valid = this.checkImageInput();
                } else if (install && !revive && other) {
                    valid = this.checkImageInput();
                } else if (!install && revive && other) {
                    valid = this.checkImageInput();
                }
            } else if (this.state.activeStep === 6) {
                if (install && revive && other) {
                    valid = this.checkImageInput();
                }
            }
        } else if (orderType === INSTALLATION) {
            // if order is installation {...}

            if (this.state.activeStep === 1) {
                valid = this.checkInstallationInputs();
            } else if (this.state.activeStep === 2) {
                valid = this.checkReceiptInput();
            } else if (this.state.activeStep === 3) {
                valid = this.checkImageInput();
            }
        } else if (orderType === REVIVE) {
            // if order is revive {...}

            if (this.state.activeStep === 1) {
                valid = this.checkReviveInputs();
            } else if (this.state.activeStep === 2) {
                valid = this.checkReceiptInput();
            } else if (this.state.activeStep === 3) {
                valid = this.checkImageInput();
            }
        } else if (orderType === FULL_PLAN) {
            // if order is maintenance plan {...}
            if (this.state.activeStep === 1) {
                if (this.state.deadPlants === 'true') {
                    valid = this.checkPlantInput(this.state.deadPlantList);
                }
            } else if (this.state.activeStep === 2) {
                if (this.state.harvestedPlants === 'true') {
                    valid = this.checkPlantInput(this.state.harvestedPlantList);
                }
            } else if (this.state.activeStep === 3) {
                if (this.state.newPlants === 'true') {
                    valid = this.checkPlantInput(this.state.newPlantList);
                }
            } else if (this.state.activeStep === 4) {
                if (this.state.plantReplacements === 'true') {
                    valid = this.checkPlantInput(
                        this.state.plantReplacementList,
                    );
                }
            } else if (this.state.activeStep === 5) {
                valid = this.checkWateringScheduleInput();
            } else if (this.state.activeStep === 6) {
                valid = this.checkCustomerNoteInput();
            } else if (this.state.activeStep === 7) {
                valid = this.checkImageInput();
            }
        } else if (orderType === MISC || orderType === SOIL_REFILL) {
            if (this.state.activeStep === 1) {
                valid = this.checkImageInput();
            }
        }

        return valid;
    }

    checkInstallationInputs() {
        const bedsValidation = validateBeds(this.state.newBeds);
        if (!bedsValidation.valid) {
            window.alert(bedsValidation.message);
        } else {
            return bedsValidation.valid;
        }
    }

    checkReviveInputs() {
        const bedsValidation = validateBeds(this.state.currentBeds);
        if (!bedsValidation.valid) {
            window.alert(bedsValidation.message);
        } else {
            return bedsValidation.valid;
        }
    }

    checkOtherServicesInputs() {
        let valid = true;

        // iterate through other services
        this.state.otherServices.forEach((otherService) => {
            // if other service description is missing {...}
            if (!otherService.description) {
                // render warning
                window.alert('Please complete all required fields');
                valid = false;
            }
        });

        return valid;
    }

    checkImageInput() {
        let valid = true;

        // if images uploaded is less than 1 {...}
        if (this.state.orderImagesList.length < 1) {
            // render warning
            window.alert('Please upload an image');
            valid = false;
        }

        return valid;
    }

    checkReceiptInput() {
        let valid = true;

        // if user reported materials purchased, but receipts uploaded is less than 1 {...}
        if (
            this.state.materialsPurchased === 'true' &&
            this.state.receiptsList.length < 1
        ) {
            // render warning
            window.alert('Please upload an image');
            valid = false;
        }

        return valid;
    }

    checkPlantInput(plants) {
        let valid = true;

        // validate plants
        const plantsValidated = this.validatePlants(plants);

        // if plants invalid, render warning
        if (!plantsValidated) {
            window.alert('Please complete all required fields');
            valid = false;
        }

        return valid;
    }

    checkWateringScheduleInput() {
        let valid = true;

        // if watering schedule invalid, render warning
        if (!this.state.wateringScheduleMinutes) {
            window.alert('Please complete all required fields');
            valid = false;
        }

        return valid;
    }

    checkCustomerNoteInput() {
        let valid = true;

        // if customer note invalid, render warning
        if (!this.state.customerNote) {
            window.alert('Please complete all required fields');
            valid = false;
        }

        return valid;
    }

    validatePlants(plants) {
        // set initial validation
        let valid = true;

        // iterate through plants
        plants.forEach((plant) => {
            // if missing details {...}
            if (!plant.qty || !plant.name) {
                // render warning
                window.alert('Please complete all required fields');
                valid = false;
            }
        });

        return valid;
    }

    uploadImages() {
        // show loading indicator
        this.setState({
            uploadingResults: true,
        });

        let uploadImagesPromises = [];
        let uploadReceiptPromises = [];
        const images = this.state.orderImagesList;
        const receipts = this.state.receiptsList;

        // if images {...}
        if (images) {
            // iterate through selected files
            for (let file in images) {
                // add to list of images to be uploaded
                uploadImagesPromises.push(
                    new Promise(async (resolve) => {
                        await this.addToImageQueue(images[file], 'image');
                        resolve();
                    }),
                );
            }

            // upload images
            Promise.all(uploadImagesPromises).then(() => {
                // if receipts {...}
                if (receipts) {
                    // iterate through selected files
                    for (let file in receipts) {
                        // add to list of receipts to be uploaded
                        uploadReceiptPromises.push(
                            new Promise(async (resolve) => {
                                await this.addToImageQueue(
                                    receipts[file],
                                    'receipt',
                                );
                                resolve();
                            }),
                        );
                    }

                    // upload images
                    Promise.all(uploadReceiptPromises).then(() => {
                        // finish processing
                        this.finish();
                    });
                } else {
                    // finish processing
                    this.finish();
                }
            });
        }
    }

    async addToImageQueue(image, type) {
        // get image url
        const hostedImg = await this.props.getImage(image.name, image.type);
        const returnData = hostedImg.data.data.returnData;
        const signedRequest = returnData.signedRequest;
        const options = {
            headers: {
                'Content-Type': image.type,
            },
        };

        // save image in S3
        await this.props.updateImage(signedRequest, image, options);

        // get processed images
        let images =
            type === 'image'
                ? this.state.processedImages
                : this.state.processedReceipts;

        // add new image to list
        images.push({
            filename: image.name,
            mimetype: image.type,
            size: image.size,
            url: hostedImg.data.data.returnData.url,
        });

        // if upload type is image {...}
        if (type === 'image') {
            // set processed images
            this.setState({
                processedImages: images,
            });
        } else if (type === 'receipt') {
            // if upload type is receipt {...}

            // set processed receipts
            this.setState({
                processedReceipts: images,
            });
        }
    }

    async finish() {
        this.setState({ imagesUploaded: true });

        // set initial order results
        let orderResults = {};

        // if images {...}
        if (this.state.processedImages.length > 0)
            orderResults.images = this.state.processedImages;

        // if receipts {...}
        if (this.state.processedReceipts.length > 0)
            orderResults.receipts = this.state.processedReceipts;

        // if order status is pending {...}
        if (this.props.order.status === 'pending') {
            // update status to complete
            orderResults.status = 'complete';
            orderResults.dateCompleted = new Date();
        }

        // if new garden {...}
        if (
            this.props.order.type === INSTALLATION ||
            this.props.order.type === REVIVE
        )
            orderResults.phase = 3;

        // update order with new results
        await this.props.updateOrder(this.props.order._id, orderResults);

        // -------------------------------------------- YARD ASSESSMENT -------------------------------------------- //
        if (this.props.order.type === YARD_ASSESSMENT)
            await this.processYardAssessment();

        // -------------------------------------------- INSTALLATION & REVIVE -------------------------------------------- //
        if (
            this.props.order.type === INSTALLATION ||
            this.props.order.type === REVIVE
        )
            await this.processNewGardenBeds();

        // -------------------------------------------- MISC & SOIL_REFILL -------------------------------------------- //
        if (
            this.props.order.type === MISC ||
            this.props.order.type === SOIL_REFILL
        )
            await this.processMisc();

        // update UI
        this.setState({
            orderImagesList: [],
            receiptsList: [],
            processedImages: [],
            processedReceipts: [],
            activeStep: 0,
        });
    }

    async processMisc() {
        // charge customer
        await this.chargePayment();

        // update progress indicator
        this.setState({
            chargedPayment: true,
            uploadComplete: true,
        });
    }

    async processNewGardenBeds() {
        await this.chargePayment();

        this.setState({ chargedPayment: true });

        const initialPlantingOrder = await this.generateInitialPlantingOrder();

        await this.generateDrafts(initialPlantingOrder);

        const specialRequest = await this.props.getSpecialRequest(
            `order=${this.props.order._id}`,
        );
        if (specialRequest && specialRequest.data) {
            await this.props.createSpecialRequest({
                order: initialPlantingOrder._id,
                description: specialRequest.data.description,
            });
        }

        this.setState({
            createdInitialPlantingOrder: true,
            uploadComplete: true,
        });
    }

    async generateDrafts(initialPlantingOrder) {
        const drafts = this.props.drafts.data || [];
        const beds = this.props.beds.data || [];
        const highestDraftKey = drafts.length > 0 ? getHighestKey(drafts) : 0;
        const highestBedKey = beds.length > 0 ? getHighestKey(beds) : 0;
        let baseKey = 0;

        if (drafts.length > 0) {
            // if unpublished drafts found {...}
            // increment from highest draft key
            baseKey = highestDraftKey;
        } else if (beds.length > 0) {
            // if beds {...}
            // increment from highest bed key
            baseKey = highestBedKey;
        }

        const bedsForOrder = this.props.order.bid.line_items.beds;
        let createDrafts = [];
        for (let i = 0; i < bedsForOrder.length; i++) {
            for (let j = 0; j < bedsForOrder[i].qty; j++) {
                baseKey += 1;
                const measurements = 2;
                const rows = (bedsForOrder[i].length / 12) * measurements;
                const columns = (bedsForOrder[i].width / 12) * measurements;
                const plotPoints = await generatePlotPoints(rows, columns);

                const newDraft = {
                    key: baseKey,
                    customer: this.props.order.customer._id,
                    order: initialPlantingOrder._id,
                    width: bedsForOrder[i].width,
                    length: bedsForOrder[i].length,
                    height: bedsForOrder[i].height,
                    shape: bedsForOrder[i].shape._id,
                    plot_points: plotPoints,
                };

                createDrafts.push(
                    new Promise(async (resolve) => {
                        await this.props.createDraft(newDraft);
                        resolve();
                    }),
                );
            }
        }

        Promise.all(createDrafts);
    }

    async processYardAssessment() {
        let jobId = false;

        // get selected services
        const selectedInstallation = this.state.gardenInstallation ? 1 : 0;
        const selectedRevive = this.state.gardenRevive ? 1 : 0;
        const selectedOtherServices = this.state.otherService ? 1 : 0;

        // determine total number of selected services
        const selectedServicesTotal =
            selectedInstallation + selectedRevive + selectedOtherServices;

        // if selected services is greater than 1 or if selected services is 1 and only other services was selected {...} {...}
        if (
            selectedServicesTotal > 1 ||
            (selectedServicesTotal === 1 &&
                selectedOtherServices &&
                this.state.otherServices.length > 1)
        ) {
            // create new job
            const newJob = await this.props.createJob();

            // set job id
            jobId = newJob.data._id;
        }

        // if customer wants an installation quote, generate quote
        if (selectedInstallation) await this.generateInstallationBid(jobId);

        // if customer wants a revive quote, generate quote
        if (selectedRevive) await this.generateReviveBid(jobId);

        // if customer wants a misc quote, generate quote
        if (selectedOtherServices) await this.generateOtherServicesBid(jobId);

        // update progress indicator
        this.setState({ uploadComplete: true });
    }

    async generateInstallationBid(jobId) {
        const customer = this.props.order.customer;
        const gardenBedProduct = this.props.products.data.find(
            (product) => product.name === 'garden bed',
        );

        let newBedsBid = {
            status: 'pending approval',
            estimated_start_dt: moment().add(2, 'weeks'),
            customer: customer._id,
            attachments: this.state.processedImages,
            type: INSTALLATION,
            title: 'Installation',
            product: gardenBedProduct._id,
            line_items: {
                beds: [],
            },
        };

        if (jobId) newBedsBid.job = jobId;
        let newBeds = [];
        let newBedsLabor = 0;
        let newBedsQuoteDescription = 'Garden Installation: ';
        const totalFeet = calculateTotalFeet(this.state.newBeds);

        newBedsLabor += totalFeet.labor;

        if (this.state.existingIrrigation) {
            // reduce labor by 1 hour
            newBedsLabor =
                newBedsLabor - vars.pricing.installation.irrigationLabor;
        }

        this.state.newBeds.forEach((bed, index) => {
            newBedsQuoteDescription += `(${bed.qty}) ${bed.shape} ${dimensions(
                bed,
            )} garden bed${bed.qty > 1 ? 's' : ''}${
                index === this.state.newBeds.length - 1 ? '' : ', '
            }`;
            const shapeId = this.props.shapes.data.find(
                (shape) => shape.name === bed.shape,
            )._id;
            let newBed = {};
            for (let item in bed) {
                if (item === 'shape') {
                    newBed[item] = shapeId;
                } else {
                    newBed[item] = bed[item];
                }
            }

            newBeds.push(newBed);
        });

        newBedsBid.description = newBedsQuoteDescription;
        newBedsBid.line_items.beds = newBeds;

        const items = await applyGardenRules(
            this.state.installationTemplate.line_items.materials,
            this.props.rules,
            totalFeet.sqft,
            totalFeet.cf,
            totalFeet.vf,
            totalFeet.lf,
        );
        let materialList = [];

        if (this.state.existingIrrigation) {
            items.forEach((material) => {
                const isIrrigationItem = this.props.irrigation.data.find(
                    // NOTE: Using the url to check an item is temporary until materials are associated with items
                    // Author: Isaac G. 2/28/23
                    (irr) => irr.item.url === material.url,
                );

                if (!isIrrigationItem) materialList.push(material);
            });
        } else {
            materialList = items;
        }

        newBedsBid.line_items.materials = materialList;
        newBedsBid.line_items.labor = {
            qty: Math.floor(newBedsLabor / vars.pricing.labor),
            price: vars.pricing.labor,
        };

        newBedsBid.line_items.delivery = {
            name: 'Soil and Lumber',
            price: vars.pricing.delivery.lumber + vars.pricing.delivery.soil,
        };

        const newBid = await this.props.createBid(newBedsBid);

        this.setState({ createdInstallationBid: true });

        if (
            this.state.gardenInstallation &&
            !this.state.gardenRevive &&
            !this.state.otherService
        ) {
            const quoteUrl = jobId
                ? `${window.location.origin}/approve?job=${jobId}`
                : `${window.location.origin}/approve?bid=${newBid.data._id}`;
            let emailMessage =
                `<p style="margin-bottom: 15px">Greetings from Yarden!</p>` +
                `<p style="margin-bottom: 15px">You have a new quote, please click the button below to view the details. If you have questions, please contact us at <a href="mailto:info@yardengarden.com">info@yardengarden.com</a>.</p>` +
                '<div style="border-top: 1px solid #DDDDDD;">' +
                '<div style="margin-top: 25px; text-align: center;">' +
                `<a href="${quoteUrl}" style="display: block; padding: 15px 30px; background-color: #330099; color: #FFFFFF; border-radius: 10px;"><b>View Quote</b></a>` +
                '</div>' +
                '</div>';

            let textMessage = `Greetings from Yarden! You received a new quote: ${quoteUrl}`;

            const account = await this.props.getAccount(customer._id);

            if (account.data && account.data.status === 'pending') {
                const redirect = `${window.location.origin}/set-password?${
                    jobId ? 'job' : 'bid'
                }=${jobId ? jobId : newBid.data._id}&user=${customer._id}`;
                emailMessage =
                    `<p style="margin-bottom: 15px">Greetings from Yarden!</p>` +
                    `<p style="margin-bottom: 15px">You received a new quote, please click the button below to view the details. If you have questions, please contact us at <a href="mailto:info@yardengarden.com">info@yardengarden.com</a>.</p>` +
                    '<div style="border-top: 1px solid #DDDDDD;">' +
                    '<div style="margin-top: 25px; text-align: center;">' +
                    `<a href="${redirect}" style="display: block; padding: 15px 30px; background-color: #330099; color: #FFFFFF; border-radius: 10px;"><b>View Quote</b></a>` +
                    '</div>' +
                    '</div>';

                textMessage = `Greetings from Yarden! You received a new quote: ${redirect}`;
            }

            const notification = {
                email: customer.email,
                subject: `Yarden - (ACTION REQUIRED) New Quote`,
                label: 'New Quote',
                body: emailMessage,
            };

            await this.props.sendEmail(notification);

            const sms = {
                from: '8888289287',
                to: customer.phone_number.replace(/\D/g, ''),
                body: textMessage,
            };

            await this.props.sendSms(sms);

            this.setState({ sentCustomerQuote: true });
        }
    }

    async generateReviveBid(jobId) {
        // set customer
        const customer = this.props.order.customer;

        // get garden bed product
        const gardenBedProduct = this.props.products.data.find(
            (product) => product.name === 'garden bed',
        );

        // format current garden bid
        let currentBedsBid = {
            status: 'bid requested',
            customer: customer._id,
            attachments: this.state.processedImages,
            type: REVIVE,
            title: 'Revive',
            product: gardenBedProduct._id,
            line_items: {
                beds: [],
            },
        };

        // if job exists {...}
        if (jobId) {
            // add job id
            currentBedsBid.job = jobId;
        } else {
            // add delivery
            currentBedsBid.line_items.delivery = {
                name: 'Soil and Lumber',
                price:
                    vars.pricing.delivery.lumber + vars.pricing.delivery.soil,
            };
        }

        // set initial current beds
        let currentBeds = [];

        // set initial quote description
        let currentBedsQuoteDescription = 'Garden Revive: ';

        // calculate garden bed total feet
        const totalFeet = calculateTotalFeet(this.state.currentBeds);

        // set initial labor
        let currentBedsLabor = totalFeet.labor;

        // if customer opted to use existing irrigation {...}
        if (this.state.existingIrrigation) {
            // reduce labor by 1 hour
            currentBedsLabor =
                currentBedsLabor - vars.pricing.revive.irrigationLabor;

            // mark bid with custom irrigation flag
            currentBedsBid.custom_irrigation = true;
        }

        // iterate through new beds
        this.state.currentBeds.forEach((bed, index) => {
            // set quote description
            currentBedsQuoteDescription += `(${bed.qty}) ${
                bed.shape
            } ${dimensions(bed)} garden bed${bed.qty > 1 ? 's' : ''}${
                index === this.state.newBeds.length - 1 ? '' : ', '
            }`;

            // set shape id
            const shapeId = this.props.shapes.data.find(
                (shape) => shape.name === bed.shape,
            )._id;

            // set new bed
            let currentBed = {};
            for (let item in bed) {
                if (item === 'shape') {
                    currentBed[item] = shapeId;
                } else {
                    currentBed[item] = bed[item];
                }
            }

            // set beds
            currentBeds.push(currentBed);
        });

        // set quote description
        currentBedsBid.description = currentBedsQuoteDescription;

        // set quote line items
        currentBedsBid.line_items.beds = currentBeds;
        currentBedsBid.line_items.labor = {
            qty: Math.floor(currentBedsLabor / vars.pricing.labor),
            price: vars.pricing.labor,
        };

        // create current garden bid
        await this.props.createBid(currentBedsBid);

        // update progress indicator
        this.setState({ createdReviveBid: true });

        const notification = {
            email: 'info@yardengarden.com',
            subject: `Yarden - (ACTION REQUIRED) New Quote`,
            label: 'New Quote',
            body:
                `<p>Hello <b>Yarden HQ</b></p>` +
                `<p>A new quote has been created for ${customer.first_name} ${customer.first_name} at ${customer.address}, please assign the quote to a vendor for completion.</p>`,
        };

        // send email notification to Yarden HQ
        await this.props.sendEmail(notification);

        // update progress indicator
        this.setState({ sentYardenReviveQuote: true });
    }

    async generateOtherServicesBid(jobId) {
        // set customer
        const customer = this.props.order.customer;

        // iterate through other services
        this.state.otherServices.forEach(async (otherService) => {
            // format other services bid
            let otherServicesBid = {
                status: 'bid requested',
                customer: customer._id,
                attachments: this.state.processedImages,
                type: MISC,
                title: 'Misc',
                description: otherService.description,
                line_items: {},
            };

            // if job exists, set job id
            if (jobId) otherServicesBid.job = jobId;

            // create new bid
            await this.props.createBid(otherServicesBid);
        });

        // update progress indicator
        this.setState({ createdOtherServicesBid: true });

        const notification = {
            email: 'info@yardengarden.com',
            subject: `Yarden - (ACTION REQUIRED) New Quote`,
            label: 'New Quote',
            body:
                `<p>Hello <b>Yarden HQ</b></p>` +
                `<p>A new quote has been created, please assign the quote to a vendor for completion.</p>`,
        };

        // send email notification to Yarden HQ
        await this.props.sendEmail(notification);

        // update progress indicator
        this.setState({ sentYardenOtherServicesQuote: true });
    }

    async generateInitialPlantingOrder() {
        // set initial planting order
        const initialPlantingOrder = {
            type: INITIAL_PLANTING,
            date: moment().add(1, 'week').startOf('day'),
            customer: this.props.order.customer._id,
            bid: this.props.order.bid._id,
            point_of_sale: window.location.pathname,
            description: vars.orderDescriptions.initialPlanting,
        };

        // create initial planting order
        const order = await this.props.createOrder(initialPlantingOrder);
        return order.data;
    }

    async chargePayment() {
        if (this.props.order.bid) {
            let description = `Labor - ${this.props.order.bid.description}`;
            let amount = 0;
            const quotePricing = getQuotePricing(this.props.order.bid);
            amount += quotePricing.secondPayment.total;

            if (this.props.changeOrders.data.length > 0) {
                this.props.changeOrders.data.forEach((changeOrder) => {
                    if (
                        changeOrder.status === 'approved' &&
                        changeOrder.line_items &&
                        changeOrder.line_items.labor
                    ) {
                        description += `; ${changeOrder.description}`;
                        const changeOrderPricing = getQuotePricing(changeOrder);
                        amount += changeOrderPricing.secondPayment.total;
                    }
                });
            }

            const total = parseFloat(amount).toFixed(2) * 100;
            const payment = {
                amount: total,
                currency: 'usd',
                statement_descriptor_suffix: 'Labor',
                description: description,
                cardId: this.props.order.customer.payment_info.card_id,
                customerId: this.props.order.customer.payment_info.customer_id,
            };

            await this.props.chargeCard(payment, this.props.order.customer);
        }
    }

    setShapeForNewBed(info) {
        // set shape
        const shape = this.props.shapes.data.find(
            (shape) => shape.name === info.value,
        );

        // set initial data
        let data = {
            shape: info.value,
            qty: 0,
        };

        // iterate through dimensions
        shape.dimensions.forEach((dimension) => {
            // set new key by using dimension names provided
            data[`${dimension}`] = 0;
        });

        // set height to 12 as default
        data.height = 12;

        // get new beds
        let beds = this.state.newBeds;

        // set new beds
        beds[info.index] = data;

        // update UI
        this.setState({ newBeds: beds });
    }

    setShapeForCurrentBed(info) {
        // set shape
        const shape = this.props.shapes.data.find(
            (shape) => shape.name === info.value,
        );

        // set initial data
        let data = {
            shape: info.value,
            qty: 0,
        };

        // iterate through dimensions
        shape.dimensions.forEach((dimension) => {
            // set new key by using dimension names provided
            data[`${dimension}`] = 0;
        });

        // set height to 12 as default
        data.height = 12;

        // get new beds
        let beds = this.state.currentBeds;

        // set new beds
        beds[info.index] = data;

        // update UI
        this.setState({ currentBeds: beds });
    }

    addRow(row) {
        switch (row) {
            case 'new bed':
                let newBeds = this.state.newBeds;
                newBeds.push({
                    shape: 'rectangle',
                    width: 0,
                    length: 0,
                    height: 12,
                    qty: 0,
                });
                this.setState({ newBeds });
                break;
            case 'current bed':
                let currentBeds = this.state.currentBeds;
                currentBeds.push({
                    shape: 'rectangle',
                    width: 0,
                    length: 0,
                    height: 12,
                    qty: 0,
                });
                this.setState({ currentBeds });
                break;
            case 'other services':
                let otherServices = this.state.otherServices;
                otherServices.push({ description: '' });
                this.setState({ otherServices });
                break;
            case 'dead plant':
                let deadPlantList = this.state.deadPlantList;
                deadPlantList.push({ name: '', qty: 0 });
                this.setState({ deadPlantList });
                break;
            case 'harvested plant':
                let harvestedPlantList = this.state.harvestedPlantList;
                harvestedPlantList.push({ name: '', qty: 0 });
                this.setState({ harvestedPlantList });
                break;
            case 'new plant':
                let newPlantList = this.state.newPlantList;
                newPlantList.push({ name: '', qty: 0 });
                this.setState({ newPlantList });
                break;
            case 'plant replacement':
                let plantReplacementList = this.state.plantReplacementList;
                plantReplacementList.push({ name: '', qty: 0 });
                this.setState({ plantReplacementList });
                break;
            default:
                return;
        }
    }

    removeRow(index, row) {
        switch (row) {
            case 'new bed':
                let newBeds = this.state.newBeds;
                newBeds.splice(index, 1);
                this.setState({ newBeds });
                break;
            case 'current bed':
                let currentBeds = this.state.currentBeds;
                currentBeds.splice(index, 1);
                this.setState({ currentBeds });
                break;
            case 'other services':
                let otherServices = this.state.otherServices;
                otherServices.splice(index, 1);
                this.setState({ otherServices });
                break;
            case 'dead plant':
                let deadPlantList = this.state.deadPlantList;
                deadPlantList.splice(index, 1);
                this.setState({ deadPlantList });
                break;
            case 'harvested plant':
                let harvestedPlantList = this.state.harvestedPlantList;
                harvestedPlantList.splice(index, 1);
                this.setState({ harvestedPlantList });
                break;
            case 'new plant':
                let newPlantList = this.state.newPlantList;
                newPlantList.splice(index, 1);
                this.setState({ newPlantList });
                break;
            case 'plant replacement':
                let plantReplacementList = this.state.plantReplacementList;
                plantReplacementList.splice(index, 1);
                this.setState({ plantReplacementList });
                break;
            default:
                return;
        }
    }

    setRowValue(info) {
        switch (info.row) {
            case 'new bed':
                if (info.value < 0) return;
                if (isNaN(info.value)) info.value = '';
                let newBeds = this.state.newBeds;
                newBeds[info.index][info.name] = info.value;
                this.setState({ newBeds });
                break;
            case 'current bed':
                if (info.value < 0) return;
                if (isNaN(info.value)) info.value = '';
                let currentBeds = this.state.currentBeds;
                currentBeds[info.index][info.name] = info.value;
                this.setState({ currentBeds });
                break;
            case 'other services':
                let otherServices = this.state.otherServices;
                otherServices[info.index][info.name] = info.value;
                this.setState({ otherServices });
                break;
            case 'dead plant':
                let deadPlantList = this.state.deadPlantList;
                deadPlantList[info.index][info.name] = info.value;
                this.setState({ deadPlantList });
                break;
            case 'harvested plant':
                let harvestedPlantList = this.state.harvestedPlantList;
                harvestedPlantList[info.index][info.name] = info.value;
                this.setState({ harvestedPlantList });
                break;
            case 'new plant':
                let newPlantList = this.state.newPlantList;
                newPlantList[info.index][info.name] = info.value;
                this.setState({ newPlantList });
                break;
            case 'plant replacement':
                let plantReplacementList = this.state.plantReplacementList;
                plantReplacementList[info.index][info.name] = info.value;
                this.setState({ plantReplacementList });
                break;
            default:
                return;
        }
    }

    addToOrderImagesList() {
        let orderImagesList = this.state.orderImagesList;

        for (let file in this.selectedFiles.files) {
            if (typeof this.selectedFiles.files[file] === 'object')
                orderImagesList.push(this.selectedFiles.files[file]);
        }

        this.setState({ orderImagesList });
    }

    addToReceiptsList() {
        let receiptsList = this.state.receiptsList;

        for (let file in this.selectedFiles.files) {
            if (typeof this.selectedFiles.files[file] === 'object')
                receiptsList.push(this.selectedFiles.files[file]);
        }

        this.setState({ receiptsList });
    }

    setStepsToSkip() {
        let stepsToSkip = 0;
        const install = this.state.gardenInstallation;
        const revive = this.state.gardenRevive;
        const other = this.state.otherService;

        if (this.state.activeStep === 2) {
            if (install && !revive && !other) {
                stepsToSkip = 0;
            } else if (install && revive && !other) {
                stepsToSkip = 0;
            } else if (install && !revive && other) {
                stepsToSkip = 0;
            } else if (!install && revive && !other) {
                stepsToSkip = 1;
            } else if (!install && revive && other) {
                stepsToSkip = 1;
            } else if (!install && !revive && other) {
                stepsToSkip = 2;
            }
        } else if (this.state.activeStep === 3) {
            if (install && !revive && !other) {
                stepsToSkip = 2;
            } else if (install && revive && !other) {
                stepsToSkip = 0;
            } else if (install && !revive && other) {
                stepsToSkip = 1;
            } else if (!install && revive && !other) {
                stepsToSkip = 2;
            } else if (!install && revive && other) {
                stepsToSkip = 1;
            } else if (!install && !revive && other) {
                stepsToSkip = 2;
            }
        } else if (this.state.activeStep === 4) {
            if (install && !revive && !other) {
                stepsToSkip = 2;
            } else if (install && revive && !other) {
                stepsToSkip = 1;
            } else if (install && !revive && other) {
                stepsToSkip = 1;
            } else if (!install && revive && !other) {
                stepsToSkip = 2;
            } else if (!install && revive && other) {
                stepsToSkip = 1;
            } else if (!install && !revive && other) {
                stepsToSkip = 2;
            }
        } else if (this.state.activeStep === 5) {
            if (install && !revive && !other) {
                stepsToSkip = 2;
            } else if (install && revive && !other) {
                stepsToSkip = 1;
            } else if (install && !revive && other) {
                stepsToSkip = 1;
            } else if (!install && revive && !other) {
                stepsToSkip = 2;
            } else if (!install && revive && other) {
                stepsToSkip = 1;
            } else if (!install && !revive && other) {
                stepsToSkip = 2;
            }
        } else if (this.state.activeStep === 6) {
            if (install && revive && !other) {
                stepsToSkip = 1;
            } else if (install && !revive && other) {
                stepsToSkip = 1;
            } else if (!install && revive && !other) {
                stepsToSkip = 1;
            } else if (!install && revive && other) {
                stepsToSkip = 1;
            } else if (!install && !revive && other) {
                stepsToSkip = 1;
            }
        }

        return stepsToSkip;
    }

    minifyDataToID(data) {
        let minifiedData = [];
        data.forEach((d) => {
            minifiedData.push({
                id: d.id._id,
                qty: d.qty,
            });
        });
        return minifiedData;
    }

    renderYardAssessmentQuestions() {
        // get active step
        const activeStep = this.state.activeStep;

        // set steps to skip
        const stepsToSkip = this.setStepsToSkip();

        switch (activeStep + stepsToSkip) {
            case 1:
                return (
                    <div>
                        <h4 className="text-purpleB90 mt20 mb15">
                            {activeStep}. What type of quote did the customer
                            request?
                        </h4>
                        <p>Check all that apply.</p>
                        <div className="flex flex-center-y">
                            <input
                                type="checkbox"
                                checked={this.state.gardenInstallation || false}
                                onChange={() => {
                                    this.setState({
                                        gardenInstallation:
                                            !this.state.gardenInstallation,
                                    });
                                }}
                            />
                            <label className="ml10">
                                Install new garden beds
                            </label>
                        </div>
                        <div className="flex flex-center-y mt10">
                            <input
                                type="checkbox"
                                checked={this.state.gardenRevive || false}
                                onChange={() => {
                                    this.setState({
                                        gardenRevive: !this.state.gardenRevive,
                                    });
                                }}
                            />
                            <label className="ml10">
                                Revive current garden beds
                            </label>
                        </div>
                        <div className="flex flex-center-y mt10">
                            <input
                                type="checkbox"
                                checked={this.state.otherService || false}
                                onChange={() => {
                                    this.setState({
                                        otherService: !this.state.otherService,
                                    });
                                }}
                            />
                            <label className="ml10">
                                Other service (i.e - fences, retaining walls,
                                etc)
                            </label>
                        </div>
                    </div>
                );
            case 2:
                return (
                    <div>
                        <h4 className="text-purpleB90 mt20 mb15">
                            {activeStep}. What type of garden beds does the
                            customer want <u>installed</u>?
                        </h4>
                        <p>Complete all required fields.</p>
                        {this.state.newBeds.map((newBed, i) => {
                            return (
                                <div key={i} className="mb15">
                                    <div className="flex flex-space-between flex-center-y">
                                        <p className="mb0">
                                            <b>Row {i + 1}</b>
                                        </p>
                                        <div className="flex flex-center-y">
                                            <p
                                                className="text-small text-purpleB mb0"
                                                onClick={() =>
                                                    this.addRow('new bed')
                                                }
                                            >
                                                <span className="flex flex-center-y">
                                                    <Icon>add</Icon>
                                                </span>
                                            </p>
                                            {this.state.newBeds.length > 1 && (
                                                <p
                                                    className="text-small text-purpleB mb0"
                                                    onClick={() =>
                                                        this.removeRow(
                                                            i,
                                                            'new bed',
                                                        )
                                                    }
                                                >
                                                    <span className="flex flex-center-y">
                                                        <Icon>
                                                            delete_outline
                                                        </Icon>
                                                    </span>
                                                </p>
                                            )}
                                        </div>
                                    </div>
                                    {this.props.shapes.data &&
                                        this.props.shapes.data
                                            .find(
                                                (shape) =>
                                                    shape.name === newBed.shape,
                                            )
                                            .dimensions.map(
                                                (dimension, ind) => (
                                                    <div key={ind}>
                                                        {dimension !==
                                                            'height' && (
                                                            <div>
                                                                <label>
                                                                    {dimension.replace(
                                                                        '_',
                                                                        ' ',
                                                                    )}{' '}
                                                                    (inches)
                                                                </label>
                                                                <input
                                                                    type="number"
                                                                    placeholder="0"
                                                                    value={
                                                                        newBed[
                                                                            dimension
                                                                        ] || 0
                                                                    }
                                                                    onChange={async (
                                                                        e,
                                                                    ) => {
                                                                        const qty =
                                                                            await setQty(
                                                                                newBed[
                                                                                    dimension
                                                                                ],
                                                                                e
                                                                                    .target
                                                                                    .value,
                                                                            );
                                                                        this.setRowValue(
                                                                            {
                                                                                name: dimension,
                                                                                value: qty,
                                                                                index: i,
                                                                                row: 'new bed',
                                                                            },
                                                                        );
                                                                    }}
                                                                />
                                                            </div>
                                                        )}
                                                        {dimension ===
                                                            'height' && (
                                                            <div className="mt10">
                                                                <div>
                                                                    <label>
                                                                        {dimension.replace(
                                                                            '_',
                                                                            ' ',
                                                                        )}{' '}
                                                                        (inches)
                                                                    </label>
                                                                </div>
                                                                <select
                                                                    className="full-width"
                                                                    value={
                                                                        newBed[
                                                                            dimension
                                                                        ] || 0
                                                                    }
                                                                    onChange={async (
                                                                        e,
                                                                    ) => {
                                                                        const qty =
                                                                            await setQty(
                                                                                newBed[
                                                                                    dimension
                                                                                ],
                                                                                e
                                                                                    .target
                                                                                    .value,
                                                                            );
                                                                        this.setRowValue(
                                                                            {
                                                                                name: dimension,
                                                                                value: qty,
                                                                                index: i,
                                                                                row: 'new bed',
                                                                            },
                                                                        );
                                                                    }}
                                                                >
                                                                    <option
                                                                        value={
                                                                            12
                                                                        }
                                                                    >
                                                                        12"
                                                                    </option>
                                                                    <option
                                                                        value={
                                                                            18
                                                                        }
                                                                    >
                                                                        18"
                                                                    </option>
                                                                    <option
                                                                        value={
                                                                            24
                                                                        }
                                                                    >
                                                                        24"
                                                                    </option>
                                                                </select>
                                                            </div>
                                                        )}
                                                    </div>
                                                ),
                                            )}
                                    <div>
                                        <label>qty</label>
                                        <input
                                            type="number"
                                            placeholder="0"
                                            value={newBed['qty'] || 0}
                                            onChange={async (e) => {
                                                const qty = await setQty(
                                                    newBed['qty'],
                                                    e.target.value,
                                                );
                                                this.setRowValue({
                                                    name: 'qty',
                                                    value: qty,
                                                    index: i,
                                                    row: 'new bed',
                                                });
                                            }}
                                        />
                                    </div>
                                </div>
                            );
                        })}
                        <div>
                            <div className="flex flex-center-y">
                                <input
                                    type="checkbox"
                                    checked={
                                        this.state.existingIrrigation || false
                                    }
                                    onChange={() => {
                                        this.setState({
                                            existingIrrigation:
                                                !this.state.existingIrrigation,
                                        });
                                    }}
                                />
                                <label className="ml10">
                                    Customer have an existing drip irrigation
                                    system that they would like to use
                                </label>
                            </div>
                        </div>
                    </div>
                );
            case 3:
                return (
                    <div>
                        <h4 className="text-purpleB90 mt20 mb15">
                            {activeStep}. What type of garden beds does the
                            customer want <u>revived</u>?
                        </h4>
                        <p>Complete all required fields.</p>
                        {this.state.currentBeds.map((currentBed, i) => {
                            return (
                                <div key={i} className="mb15">
                                    <div className="flex flex-space-between flex-center-y">
                                        <p className="mb0">
                                            <b>Row {i + 1}</b>
                                        </p>
                                        <div className="flex flex-center-y">
                                            <p
                                                className="text-small text-purpleB mb0"
                                                onClick={() =>
                                                    this.addRow('current bed')
                                                }
                                            >
                                                <span className="flex flex-center-y">
                                                    <Icon>add</Icon>
                                                </span>
                                            </p>
                                            {this.state.currentBeds.length >
                                                1 && (
                                                <p
                                                    className="text-small text-purpleB mb0"
                                                    onClick={() =>
                                                        this.removeRow(
                                                            i,
                                                            'current bed',
                                                        )
                                                    }
                                                >
                                                    <span className="flex flex-center-y">
                                                        <Icon>
                                                            delete_outline
                                                        </Icon>
                                                    </span>
                                                </p>
                                            )}
                                        </div>
                                    </div>
                                    {this.props.shapes.data &&
                                        this.props.shapes.data
                                            .find(
                                                (shape) =>
                                                    shape.name ===
                                                    currentBed.shape,
                                            )
                                            .dimensions.map(
                                                (dimension, ind) => (
                                                    <div key={ind}>
                                                        {dimension ===
                                                            'height' && (
                                                            <div className="mt10">
                                                                <div>
                                                                    <label>
                                                                        {dimension.replace(
                                                                            '_',
                                                                            ' ',
                                                                        )}{' '}
                                                                        (inches)
                                                                    </label>
                                                                </div>
                                                                <select
                                                                    value={
                                                                        currentBed[
                                                                            dimension
                                                                        ] || 0
                                                                    }
                                                                    onChange={async (
                                                                        e,
                                                                    ) => {
                                                                        const qty =
                                                                            await setQty(
                                                                                currentBed[
                                                                                    dimension
                                                                                ],
                                                                                e
                                                                                    .target
                                                                                    .value,
                                                                            );
                                                                        this.setRowValue(
                                                                            {
                                                                                name: dimension,
                                                                                value: qty,
                                                                                index: i,
                                                                                row: 'current bed',
                                                                            },
                                                                        );
                                                                    }}
                                                                >
                                                                    <option
                                                                        value={
                                                                            12
                                                                        }
                                                                    >
                                                                        12
                                                                    </option>
                                                                    <option
                                                                        value={
                                                                            18
                                                                        }
                                                                    >
                                                                        18
                                                                    </option>
                                                                    <option
                                                                        value={
                                                                            24
                                                                        }
                                                                    >
                                                                        24
                                                                    </option>
                                                                    <option
                                                                        value={
                                                                            36
                                                                        }
                                                                    >
                                                                        36
                                                                    </option>
                                                                </select>
                                                            </div>
                                                        )}
                                                        {dimension !==
                                                            'height' && (
                                                            <div>
                                                                <label>
                                                                    {dimension.replace(
                                                                        '_',
                                                                        ' ',
                                                                    )}{' '}
                                                                    (inches)
                                                                </label>
                                                                <input
                                                                    type="number"
                                                                    placeholder="0"
                                                                    value={
                                                                        currentBed[
                                                                            dimension
                                                                        ] || 0
                                                                    }
                                                                    onChange={async (
                                                                        e,
                                                                    ) => {
                                                                        const qty =
                                                                            await setQty(
                                                                                currentBed[
                                                                                    dimension
                                                                                ],
                                                                                e
                                                                                    .target
                                                                                    .value,
                                                                            );
                                                                        this.setRowValue(
                                                                            {
                                                                                name: dimension,
                                                                                value: qty,
                                                                                index: i,
                                                                                row: 'current bed',
                                                                            },
                                                                        );
                                                                    }}
                                                                />
                                                            </div>
                                                        )}
                                                    </div>
                                                ),
                                            )}
                                    <div>
                                        <label>qty</label>
                                        <input
                                            type="number"
                                            placeholder="0"
                                            value={currentBed['qty'] || 0}
                                            onChange={async (e) => {
                                                const qty = await setQty(
                                                    currentBed['qty'],
                                                    e.target.value,
                                                );
                                                this.setRowValue({
                                                    name: 'qty',
                                                    value: qty,
                                                    index: i,
                                                    row: 'current bed',
                                                });
                                            }}
                                        />
                                    </div>
                                </div>
                            );
                        })}
                        <div>
                            <div className="flex flex-center-y">
                                <input
                                    type="checkbox"
                                    checked={
                                        this.state.existingIrrigation || false
                                    }
                                    onChange={() => {
                                        this.setState({
                                            existingIrrigation:
                                                !this.state.existingIrrigation,
                                        });
                                    }}
                                />
                                <label className="ml10">
                                    Customer have an existing drip irrigation
                                    system that they would like to use
                                </label>
                            </div>
                        </div>
                    </div>
                );
            case 4:
                return (
                    <div>
                        <h4 className="text-purpleB90 mt20 mb15">
                            {activeStep}. What <u>other services</u> does the
                            customer want quoted?
                        </h4>
                        <p>
                            Complete all required fields. A new quote will be
                            generated for each row you add.
                        </p>
                        {this.state.otherServices.map((otherService, i) => (
                            <div key={i}>
                                <div className="flex flex-space-between flex-center-y">
                                    <p>
                                        <b>Row {i + 1}</b>
                                    </p>
                                    <div className="flex flex-center-y">
                                        <p
                                            className="text-small text-purpleB"
                                            onClick={() =>
                                                this.addRow('other services')
                                            }
                                        >
                                            <span className="flex flex-center-y">
                                                <Icon>add</Icon>
                                            </span>
                                        </p>
                                        {this.state.otherServices.length >
                                            1 && (
                                            <p
                                                className="text-small text-purpleB"
                                                onClick={() =>
                                                    this.removeRow(
                                                        i,
                                                        'other services',
                                                    )
                                                }
                                            >
                                                <span className="flex flex-center-y">
                                                    <Icon>delete_outline</Icon>
                                                </span>
                                            </p>
                                        )}
                                    </div>
                                </div>
                                <textarea
                                    type="text"
                                    placeholder="New fence, 20 ft long and 6 ft tall..."
                                    value={otherService.description || ''}
                                    onChange={(e) =>
                                        this.setRowValue({
                                            index: i,
                                            name: 'description',
                                            value: e.target.value,
                                            row: 'other services',
                                        })
                                    }
                                />
                            </div>
                        ))}
                    </div>
                );
            case 5:
                return (
                    <div>
                        <h4 className="text-purpleB90 mt20 mb15">
                            {activeStep}. Upload images
                        </h4>
                        <p>
                            Upload images of the garden site. If the customer
                            requested other services, upload additional images
                            to support.
                        </p>
                        <div
                            onClick={() =>
                                document.getElementById('image-upload').click()
                            }
                            style={{
                                height: 200,
                                border: '2px dashed #EEEEEE',
                            }}
                            className="flex flex-center curved pointer"
                        >
                            <div>
                                <div className="flex flex-center">
                                    <Icon style={{ color: 'var(--purpleB)' }}>
                                        file_upload
                                    </Icon>
                                </div>
                                <div className="flex flex-center">
                                    <p className="mt5">
                                        {this.state.orderImagesList.length > 0
                                            ? `${
                                                  this.state.orderImagesList
                                                      .length
                                              } image${
                                                  this.state.orderImagesList
                                                      .length > 1
                                                      ? 's'
                                                      : ''
                                              } selected`
                                            : 'Click here to upload'}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <input
                            multiple
                            id="image-upload"
                            type="file"
                            className="filepicker invisible"
                            ref={(inp) => (this.selectedFiles = inp)}
                            onChange={() => this.addToOrderImagesList()}
                        />
                        <div className="flex flex-space-between mt15">
                            <div></div>
                            <p
                                className="text-small text-purpleB"
                                onClick={() =>
                                    this.setState({ orderImagesList: [] })
                                }
                            >
                                <span className="flex flex-center-y">
                                    Reset <Icon>restart_alt</Icon>
                                </span>
                            </p>
                        </div>
                    </div>
                );
            case 6:
                return (
                    <div>
                        <h4 className="text-purpleB90 mt20 mb15">
                            {activeStep}. Submit report
                        </h4>
                        <p>Review all reported results and submit.</p>
                        {this.state.gardenInstallation && (
                            <div>
                                <p className="text-small">
                                    <b>Beds to install</b>
                                </p>
                                {this.state.newBeds.map((newBed, index) => (
                                    <div key={index}>
                                        <p className="text-small">
                                            ({newBed.qty}) {newBed.shape}{' '}
                                            {dimensions(newBed)} bed
                                            {newBed.qty > 1 ? 's' : ''}
                                        </p>
                                    </div>
                                ))}
                                <Divider />
                            </div>
                        )}
                        {this.state.gardenRevive && (
                            <div>
                                <p className="text-small">
                                    <b>Beds to revive</b>
                                </p>
                                {this.state.currentBeds.map(
                                    (currentBed, index) => (
                                        <div key={index}>
                                            <p className="text-small">
                                                ({currentBed.qty}){' '}
                                                {currentBed.shape}{' '}
                                                {dimensions(currentBed)} bed
                                                {currentBed.qty > 1 ? 's' : ''}
                                            </p>
                                        </div>
                                    ),
                                )}
                                <Divider />
                            </div>
                        )}
                        {this.state.otherService && (
                            <div>
                                <p className="text-small">
                                    <b>Other services</b>
                                </p>
                                {this.state.otherServices.map(
                                    (otherService, index) => (
                                        <div key={index}>
                                            <p className="text-small">
                                                {index + 1}.{' '}
                                                {otherService.description}
                                            </p>
                                        </div>
                                    ),
                                )}
                                <Divider />
                            </div>
                        )}
                        <Divider />
                        <div className="mt15">
                            <p className="text-small">
                                <b>Total photos</b>
                            </p>
                            <p className="text-small">
                                {this.state.orderImagesList.length}
                            </p>
                        </div>
                        <Divider />
                    </div>
                );
            default:
                return <div></div>;
        }
    }

    renderNewGardenBedsSteps() {
        // get active step
        const activeStep = this.state.activeStep;

        // get action
        const action =
            this.props.order.type === INSTALLATION ? 'installed' : 'revived';

        // get beds
        const beds =
            this.props.order.type === INSTALLATION
                ? this.state.newBeds
                : this.state.currentBeds;

        switch (activeStep) {
            case 1:
                return (
                    <div>
                        <h4 className="text-purpleB90 mt20 mb15">
                            {activeStep}. What type of garden beds were{' '}
                            <u>{action}</u>?
                        </h4>
                        <p>Complete all required fields.</p>
                        {beds.map((bed, i) => {
                            return (
                                <div key={i}>
                                    <div className="flex flex-space-between flex-center-y">
                                        <p>
                                            <b>Row {i + 1}</b>
                                        </p>
                                        <div className="flex flex-center-y">
                                            <p
                                                className="text-small text-purpleB"
                                                onClick={() => {
                                                    if (
                                                        this.props.order
                                                            .type ===
                                                        INSTALLATION
                                                    )
                                                        this.addRow('new bed');
                                                    if (
                                                        this.props.order
                                                            .type === REVIVE
                                                    )
                                                        this.addRow(
                                                            'current bed',
                                                        );
                                                }}
                                            >
                                                <span className="flex flex-center-y">
                                                    <Icon>add</Icon>
                                                </span>
                                            </p>
                                            {beds.length > 1 && (
                                                <p
                                                    className="text-small text-purpleB"
                                                    onClick={() => {
                                                        if (
                                                            this.props.order
                                                                .type ===
                                                            INSTALLATION
                                                        )
                                                            this.removeRow(
                                                                i,
                                                                'new bed',
                                                            );
                                                        if (
                                                            this.props.order
                                                                .type === REVIVE
                                                        )
                                                            this.removeRow(
                                                                i,
                                                                'current bed',
                                                            );
                                                    }}
                                                >
                                                    <span className="flex flex-center-y">
                                                        <Icon>
                                                            delete_outline
                                                        </Icon>
                                                    </span>
                                                </p>
                                            )}
                                        </div>
                                    </div>
                                    {this.state[`show${i}NewImage`] && (
                                        <img
                                            src={
                                                this.props.shapes.data.find(
                                                    (shape) =>
                                                        shape.name ===
                                                        bed.shape,
                                                ).image
                                            }
                                            width="100%"
                                            alt="bed shape"
                                        />
                                    )}
                                    <div className="flex flex-center-y">
                                        <label>Bed Shape</label>
                                        <Icon
                                            style={{ color: '#CCCCCC' }}
                                            onClick={() => {
                                                this.setState({
                                                    [`show${i}NewImage`]:
                                                        !this.state[
                                                            `show${i}NewImage`
                                                        ],
                                                });
                                            }}
                                        >
                                            info
                                        </Icon>
                                    </div>
                                    <select
                                        onChange={(e) => {
                                            if (
                                                this.props.order.type ===
                                                INSTALLATION
                                            ) {
                                                this.setShapeForNewBed({
                                                    name: 'shape',
                                                    value: e.target.value,
                                                    index: i,
                                                });
                                            } else if (
                                                this.props.order.type === REVIVE
                                            ) {
                                                this.setShapeForCurrentBed({
                                                    name: 'shape',
                                                    value: e.target.value,
                                                    index: i,
                                                });
                                            }
                                        }}
                                        value={bed.shape}
                                    >
                                        {this.props.shapes.data &&
                                            this.props.shapes.data.map(
                                                (shape, index) => (
                                                    <option
                                                        disabled={
                                                            shape.name !==
                                                            'rectangle'
                                                        }
                                                        key={index}
                                                        value={shape.name}
                                                    >
                                                        {shape.name}
                                                    </option>
                                                ),
                                            )}
                                    </select>
                                    {this.props.shapes.data &&
                                        this.props.shapes.data
                                            .find(
                                                (shape) =>
                                                    shape.name === bed.shape,
                                            )
                                            .dimensions.map(
                                                (dimension, ind) => (
                                                    <div key={ind}>
                                                        {dimension ===
                                                            'height' && (
                                                            <div className="mt10">
                                                                <div>
                                                                    <label>
                                                                        {dimension.replace(
                                                                            '_',
                                                                            ' ',
                                                                        )}{' '}
                                                                        (inches)
                                                                    </label>
                                                                </div>
                                                                <select
                                                                    value={
                                                                        bed[
                                                                            dimension
                                                                        ] || 0
                                                                    }
                                                                    onChange={async (
                                                                        e,
                                                                    ) => {
                                                                        const qty =
                                                                            await setQty(
                                                                                bed[
                                                                                    dimension
                                                                                ],
                                                                                e
                                                                                    .target
                                                                                    .value,
                                                                            );
                                                                        if (
                                                                            this
                                                                                .props
                                                                                .order
                                                                                .type ===
                                                                            INSTALLATION
                                                                        ) {
                                                                            this.setRowValue(
                                                                                {
                                                                                    name: dimension,
                                                                                    value: qty,
                                                                                    index: i,
                                                                                    row: 'new bed',
                                                                                },
                                                                            );
                                                                        } else if (
                                                                            this
                                                                                .props
                                                                                .order
                                                                                .type ===
                                                                            REVIVE
                                                                        ) {
                                                                            this.setRowValue(
                                                                                {
                                                                                    name: dimension,
                                                                                    value: qty,
                                                                                    index: i,
                                                                                    row: 'current bed',
                                                                                },
                                                                            );
                                                                        }
                                                                    }}
                                                                >
                                                                    <option
                                                                        value={
                                                                            12
                                                                        }
                                                                    >
                                                                        12
                                                                    </option>
                                                                    <option
                                                                        value={
                                                                            18
                                                                        }
                                                                    >
                                                                        18
                                                                    </option>
                                                                    <option
                                                                        value={
                                                                            24
                                                                        }
                                                                    >
                                                                        24
                                                                    </option>
                                                                    <option
                                                                        value={
                                                                            36
                                                                        }
                                                                    >
                                                                        36
                                                                    </option>
                                                                </select>
                                                            </div>
                                                        )}
                                                        {dimension !==
                                                            'height' && (
                                                            <div>
                                                                <label>
                                                                    {dimension.replace(
                                                                        '_',
                                                                        ' ',
                                                                    )}{' '}
                                                                    (inches)
                                                                </label>
                                                                <input
                                                                    type="number"
                                                                    placeholder="0"
                                                                    value={
                                                                        bed[
                                                                            dimension
                                                                        ] || 0
                                                                    }
                                                                    onChange={async (
                                                                        e,
                                                                    ) => {
                                                                        const qty =
                                                                            await setQty(
                                                                                bed[
                                                                                    dimension
                                                                                ],
                                                                                e
                                                                                    .target
                                                                                    .value,
                                                                            );
                                                                        if (
                                                                            this
                                                                                .props
                                                                                .order
                                                                                .type ===
                                                                            INSTALLATION
                                                                        ) {
                                                                            this.setRowValue(
                                                                                {
                                                                                    name: dimension,
                                                                                    value: qty,
                                                                                    index: i,
                                                                                    row: 'new bed',
                                                                                },
                                                                            );
                                                                        } else if (
                                                                            this
                                                                                .props
                                                                                .order
                                                                                .type ===
                                                                            REVIVE
                                                                        ) {
                                                                            this.setRowValue(
                                                                                {
                                                                                    name: dimension,
                                                                                    value: qty,
                                                                                    index: i,
                                                                                    row: 'current bed',
                                                                                },
                                                                            );
                                                                        }
                                                                    }}
                                                                />
                                                            </div>
                                                        )}
                                                    </div>
                                                ),
                                            )}
                                    <div>
                                        <label>qty</label>
                                        <input
                                            type="number"
                                            placeholder="0"
                                            value={bed['qty'] || 0}
                                            onChange={async (e) => {
                                                const qty = await setQty(
                                                    bed['qty'],
                                                    e.target.value,
                                                );
                                                if (
                                                    this.props.order.type ===
                                                    INSTALLATION
                                                ) {
                                                    this.setRowValue({
                                                        name: 'qty',
                                                        value: qty,
                                                        index: i,
                                                        row: 'new bed',
                                                    });
                                                } else if (
                                                    this.props.order.type ===
                                                    REVIVE
                                                ) {
                                                    this.setRowValue({
                                                        name: 'qty',
                                                        value: qty,
                                                        index: i,
                                                        row: 'current bed',
                                                    });
                                                }
                                            }}
                                        />
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                );
            case 2:
                return (
                    <div>
                        <h4 className="text-purpleB90 mt20 mb15">
                            {activeStep}. Did you purchase any materials?
                        </h4>
                        <p>
                            If you had to purchase materials that were missing
                            or not included in the original quote, please upload
                            your receipts for reimbursement.
                        </p>
                        <div className="radio-header">
                            <div className="flex-center-y">
                                <input
                                    type="radio"
                                    checked={this.state.materialsPurchased}
                                    onChange={() =>
                                        this.setState({
                                            materialsPurchased: true,
                                        })
                                    }
                                />
                                <h5 className="ml4">Yes</h5>
                            </div>
                        </div>
                        <div className="radio-header mt15">
                            <div className="flex-center-y">
                                <input
                                    type="radio"
                                    checked={!this.state.materialsPurchased}
                                    onChange={() =>
                                        this.setState({
                                            materialsPurchased: false,
                                        })
                                    }
                                />
                                <h5 className="ml4">No</h5>
                            </div>
                        </div>
                        {this.state.materialsPurchased === 'true' && (
                            <div>
                                <div
                                    onClick={() =>
                                        document
                                            .getElementById('receipt-upload')
                                            .click()
                                    }
                                    style={{
                                        height: 200,
                                        border: '2px dashed #EEEEEE',
                                    }}
                                    className="flex flex-center curved pointer"
                                >
                                    <div>
                                        <div className="flex flex-center">
                                            <Icon
                                                style={{
                                                    color: 'var(--purpleB)',
                                                }}
                                            >
                                                receipt_long
                                            </Icon>
                                        </div>
                                        <div className="flex flex-center">
                                            <p className="mt5">
                                                {this.state.receiptsList
                                                    .length > 0
                                                    ? `${
                                                          this.state
                                                              .receiptsList
                                                              .length
                                                      } image${
                                                          this.state
                                                              .receiptsList
                                                              .length > 1
                                                              ? 's'
                                                              : ''
                                                      } selected`
                                                    : 'Click here to upload'}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <input
                                    multiple
                                    id="receipt-upload"
                                    type="file"
                                    className="filepicker invisible"
                                    ref={(inp) => (this.selectedFiles = inp)}
                                    onChange={() => this.addToReceiptsList()}
                                />
                                <div className="flex flex-space-between mt15">
                                    <div></div>
                                    <p
                                        className="text-small text-purpleB"
                                        onClick={() =>
                                            this.setState({ receiptsList: [] })
                                        }
                                    >
                                        <span className="flex flex-center-y">
                                            Reset <Icon>restart_alt</Icon>
                                        </span>
                                    </p>
                                </div>
                            </div>
                        )}
                    </div>
                );
            case 3:
                return (
                    <div>
                        <h4 className="text-purpleB90 mt20 mb15">
                            {activeStep}. Upload images
                        </h4>
                        <p>Upload images of the garden beds.</p>
                        <div
                            onClick={() =>
                                document.getElementById('image-upload').click()
                            }
                            style={{
                                height: 200,
                                border: '2px dashed #EEEEEE',
                            }}
                            className="flex flex-center curved pointer"
                        >
                            <div>
                                <div className="flex flex-center">
                                    <Icon style={{ color: 'var(--purpleB)' }}>
                                        file_upload
                                    </Icon>
                                </div>
                                <div className="flex flex-center">
                                    <p className="mt5">
                                        {this.state.orderImagesList.length > 0
                                            ? `${
                                                  this.state.orderImagesList
                                                      .length
                                              } image${
                                                  this.state.orderImagesList
                                                      .length > 1
                                                      ? 's'
                                                      : ''
                                              } selected`
                                            : 'Click here to upload'}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <input
                            multiple
                            id="image-upload"
                            type="file"
                            className="filepicker invisible"
                            ref={(inp) => (this.selectedFiles = inp)}
                            onChange={() => this.addToOrderImagesList()}
                        />
                        <div className="flex flex-space-between mt15">
                            <div></div>
                            <p
                                className="text-small text-purpleB"
                                onClick={() =>
                                    this.setState({ orderImagesList: [] })
                                }
                            >
                                <span className="flex flex-center-y">
                                    Reset <Icon>restart_alt</Icon>
                                </span>
                            </p>
                        </div>
                    </div>
                );
            case 4:
                return (
                    <div>
                        <h4 className="text-purpleB90 mt20 mb15">
                            {activeStep}. Submit report
                        </h4>
                        <p>Review all reported results and submit.</p>
                        <div>
                            <p className="text-small">
                                <b>
                                    Beds{' '}
                                    {this.props.order.type === INSTALLATION
                                        ? 'installed'
                                        : 'revived'}
                                </b>
                            </p>
                            {beds.map((bed, index) => (
                                <div key={index}>
                                    <p className="text-small">
                                        ({bed.qty}) {bed.shape}{' '}
                                        {dimensions(bed)} bed
                                        {bed.qty > 1 ? 's' : ''}
                                    </p>
                                </div>
                            ))}
                        </div>
                        <Divider />
                        <div>
                            <p className="text-small">
                                <b>Total photos</b>
                            </p>
                            <p className="text-small">
                                {this.state.orderImagesList.length}
                            </p>
                        </div>
                        <Divider />
                        {this.state.receiptsList.length > 0 && (
                            <div>
                                <div>
                                    <p className="text-small">
                                        <b>Receipts</b>
                                    </p>
                                    <p className="text-small">
                                        {this.state.receiptsList.length}
                                    </p>
                                </div>
                                <Divider />
                            </div>
                        )}
                    </div>
                );
            default:
                return <div></div>;
        }
    }

    renderMiscServiceSteps() {
        // get active step
        const activeStep = this.state.activeStep;

        switch (activeStep) {
            case 1:
                return (
                    <div>
                        <h4 className="text-purpleB90 mt20 mb15">
                            {activeStep}. Upload images
                        </h4>
                        <p>
                            Please upload a minimum of 2 images. Upload
                            additional images as neeed.
                        </p>
                        <div
                            onClick={() =>
                                document.getElementById('image-upload').click()
                            }
                            style={{
                                height: 200,
                                border: '2px dashed #EEEEEE',
                            }}
                            className="flex flex-center curved pointer"
                        >
                            <div>
                                <div className="flex flex-center">
                                    <Icon style={{ color: 'var(--purpleB)' }}>
                                        file_upload
                                    </Icon>
                                </div>
                                <div className="flex flex-center">
                                    <p className="mt5">
                                        {this.state.orderImagesList.length > 0
                                            ? `${
                                                  this.state.orderImagesList
                                                      .length
                                              } image${
                                                  this.state.orderImagesList
                                                      .length > 1
                                                      ? 's'
                                                      : ''
                                              } selected`
                                            : 'Click here to upload'}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <input
                            multiple
                            id="image-upload"
                            type="file"
                            className="filepicker invisible"
                            ref={(inp) => (this.selectedFiles = inp)}
                            onChange={() => this.addToOrderImagesList()}
                        />
                        <div className="flex flex-space-between mt15">
                            <div></div>
                            <p
                                className="text-small text-purpleB"
                                onClick={() =>
                                    this.setState({ orderImagesList: [] })
                                }
                            >
                                <span className="flex flex-center-y">
                                    Reset <Icon>restart_alt</Icon>
                                </span>
                            </p>
                        </div>
                    </div>
                );
            case 2:
                return (
                    <div>
                        <h4 className="text-purpleB90 mt20 mb15">
                            {activeStep}. Submit report
                        </h4>
                        <p>Review all reported results and submit.</p>
                        <div>
                            <p className="text-small">
                                <b>Total photos</b>
                            </p>
                            <p className="text-small">
                                {this.state.orderImagesList.length}
                            </p>
                        </div>
                    </div>
                );
            default:
                return <div></div>;
        }
    }

    renderMaintenancePlanSteps() {
        // get active step
        const activeStep = this.state.activeStep;

        switch (activeStep) {
            case 1:
                return (
                    <div>
                        <h4 className="text-purpleB90 mt20 mb15">
                            {activeStep}. Did you find any dead plants during
                            this service?
                        </h4>
                        <p>Please make a selection.</p>
                        <div>
                            <FormControl component="fieldset">
                                <RadioGroup
                                    value={this.state.deadPlants || 'false'}
                                    onChange={(e) =>
                                        this.setState({
                                            deadPlants: e.target.value,
                                        })
                                    }
                                >
                                    <FormControlLabel
                                        value={'true'}
                                        control={<Radio />}
                                        label={
                                            <span>
                                                <div className="text-black">
                                                    <b>Yes</b>
                                                </div>
                                            </span>
                                        }
                                    />
                                    <FormControlLabel
                                        value={'false'}
                                        control={<Radio />}
                                        label={
                                            <span>
                                                <div className="text-black">
                                                    <b>No</b>
                                                </div>
                                            </span>
                                        }
                                    />
                                </RadioGroup>
                            </FormControl>
                        </div>
                        {this.state.deadPlants === 'true' &&
                            this.state.deadPlantList.map((deadPlant, i) => (
                                <div key={i}>
                                    <div className="flex flex-space-between flex-center-y">
                                        <p>
                                            <b>Plant {i + 1}</b>
                                        </p>
                                        <div className="flex flex-center-y">
                                            <p
                                                className="text-small text-purpleB"
                                                onClick={() =>
                                                    this.addRow('dead plant')
                                                }
                                            >
                                                <span className="flex flex-center-y">
                                                    <Icon>add</Icon>
                                                </span>
                                            </p>
                                            {this.state.deadPlantList.length >
                                                1 && (
                                                <p
                                                    className="text-small text-purpleB"
                                                    onClick={() =>
                                                        this.removeRow(
                                                            i,
                                                            'dead plant',
                                                        )
                                                    }
                                                >
                                                    <span className="flex flex-center-y">
                                                        <Icon>
                                                            delete_outline
                                                        </Icon>
                                                    </span>
                                                </p>
                                            )}
                                        </div>
                                    </div>
                                    <div>
                                        <label>Plant Name</label>
                                        <input
                                            placeholder="Tomato"
                                            value={deadPlant['name'] || ''}
                                            onChange={async (e) =>
                                                this.setRowValue({
                                                    index: i,
                                                    name: 'name',
                                                    value: e.target.value,
                                                    row: 'dead plant',
                                                })
                                            }
                                        />
                                    </div>
                                    <div>
                                        <label>Qty</label>
                                        <input
                                            type="number"
                                            placeholder="0"
                                            value={deadPlant['qty'] || 0}
                                            onChange={async (e) => {
                                                const qty = await setQty(
                                                    deadPlant['qty'],
                                                    e.target.value,
                                                );
                                                this.setRowValue({
                                                    index: i,
                                                    name: 'qty',
                                                    value: qty,
                                                    row: 'dead plant',
                                                });
                                            }}
                                        />
                                    </div>
                                </div>
                            ))}
                    </div>
                );
            case 2:
                return (
                    <div>
                        <h4 className="text-purpleB90 mt20 mb15">
                            {activeStep}. Did you harvest any vegetables, herbs,
                            or fruit?
                        </h4>
                        <p>Please make a selection.</p>
                        <div>
                            <FormControl component="fieldset">
                                <RadioGroup
                                    value={
                                        this.state.harvestedPlants || 'false'
                                    }
                                    onChange={(e) =>
                                        this.setState({
                                            harvestedPlants: e.target.value,
                                        })
                                    }
                                >
                                    <FormControlLabel
                                        value={'true'}
                                        control={<Radio />}
                                        label={
                                            <span>
                                                <div className="text-black">
                                                    <b>Yes</b>
                                                </div>
                                            </span>
                                        }
                                    />
                                    <FormControlLabel
                                        value={'false'}
                                        control={<Radio />}
                                        label={
                                            <span>
                                                <div className="text-black">
                                                    <b>No</b>
                                                </div>
                                            </span>
                                        }
                                    />
                                </RadioGroup>
                            </FormControl>
                        </div>
                        {this.state.harvestedPlants === 'true' &&
                            this.state.harvestedPlantList.map(
                                (harvestedPlant, i) => (
                                    <div key={i}>
                                        <div className="flex flex-space-between flex-center-y">
                                            <p>
                                                <b>Plant {i + 1}</b>
                                            </p>
                                            <div className="flex flex-center-y">
                                                <p
                                                    className="text-small text-purpleB"
                                                    onClick={() =>
                                                        this.addRow(
                                                            'harvested plant',
                                                        )
                                                    }
                                                >
                                                    <span className="flex flex-center-y">
                                                        <Icon>add</Icon>
                                                    </span>
                                                </p>
                                                {this.state.harvestedPlantList
                                                    .length > 1 && (
                                                    <p
                                                        className="text-small text-purpleB"
                                                        onClick={() =>
                                                            this.removeRow(
                                                                i,
                                                                'harvested plant',
                                                            )
                                                        }
                                                    >
                                                        <span className="flex flex-center-y">
                                                            <Icon>
                                                                delete_outline
                                                            </Icon>
                                                        </span>
                                                    </p>
                                                )}
                                            </div>
                                        </div>
                                        <div>
                                            <label>Plant Name</label>
                                            <input
                                                placeholder="Tomato"
                                                value={
                                                    harvestedPlant['name'] || ''
                                                }
                                                onChange={async (e) =>
                                                    this.setRowValue({
                                                        index: i,
                                                        name: 'name',
                                                        value: e.target.value,
                                                        row: 'harvested plant',
                                                    })
                                                }
                                            />
                                        </div>
                                        <div>
                                            <label>Weight (lbs)</label>
                                            <input
                                                type="number"
                                                placeholder="0"
                                                value={
                                                    harvestedPlant['qty'] || 0
                                                }
                                                onChange={async (e) => {
                                                    const qty = await setQty(
                                                        harvestedPlant['qty'],
                                                        e.target.value,
                                                    );
                                                    this.setRowValue({
                                                        index: i,
                                                        name: 'qty',
                                                        value: qty,
                                                        row: 'harvested plant',
                                                    });
                                                }}
                                            />
                                        </div>
                                    </div>
                                ),
                            )}
                    </div>
                );
            case 3:
                return (
                    <div>
                        <h4 className="text-purpleB90 mt20 mb15">
                            {activeStep}. Did you plant anything new today?
                        </h4>
                        <p>Please make a selection.</p>
                        <div>
                            <FormControl component="fieldset">
                                <RadioGroup
                                    value={this.state.newPlants || 'false'}
                                    onChange={(e) =>
                                        this.setState({
                                            newPlants: e.target.value,
                                        })
                                    }
                                >
                                    <FormControlLabel
                                        value={'true'}
                                        control={<Radio />}
                                        label={
                                            <span>
                                                <div className="text-black">
                                                    <b>Yes</b>
                                                </div>
                                            </span>
                                        }
                                    />
                                    <FormControlLabel
                                        value={'false'}
                                        control={<Radio />}
                                        label={
                                            <span>
                                                <div className="text-black">
                                                    <b>No</b>
                                                </div>
                                            </span>
                                        }
                                    />
                                </RadioGroup>
                            </FormControl>
                        </div>
                        {this.state.newPlants === 'true' &&
                            this.state.newPlantList.map((newPlant, i) => (
                                <div key={i}>
                                    <div className="flex flex-space-between flex-center-y">
                                        <p>
                                            <b>Plant {i + 1}</b>
                                        </p>
                                        <div className="flex flex-center-y">
                                            <p
                                                className="text-small text-purpleB"
                                                onClick={() =>
                                                    this.addRow('new plant')
                                                }
                                            >
                                                <span className="flex flex-center-y">
                                                    <Icon>add</Icon>
                                                </span>
                                            </p>
                                            {this.state.newPlantList.length >
                                                1 && (
                                                <p
                                                    className="text-small text-purpleB"
                                                    onClick={() =>
                                                        this.removeRow(
                                                            i,
                                                            'new plant',
                                                        )
                                                    }
                                                >
                                                    <span className="flex flex-center-y">
                                                        <Icon>
                                                            delete_outline
                                                        </Icon>
                                                    </span>
                                                </p>
                                            )}
                                        </div>
                                    </div>
                                    <div>
                                        <label>Plant Name</label>
                                        <input
                                            placeholder="Tomato"
                                            value={newPlant['name'] || ''}
                                            onChange={async (e) =>
                                                this.setRowValue({
                                                    index: i,
                                                    name: 'name',
                                                    value: e.target.value,
                                                    row: 'new plant',
                                                })
                                            }
                                        />
                                    </div>
                                    <div>
                                        <label>Qty</label>
                                        <input
                                            type="number"
                                            placeholder="0"
                                            value={newPlant['qty'] || 0}
                                            onChange={async (e) => {
                                                const qty = await setQty(
                                                    newPlant['qty'],
                                                    e.target.value,
                                                );
                                                this.setRowValue({
                                                    index: i,
                                                    name: 'qty',
                                                    value: qty,
                                                    row: 'new plant',
                                                });
                                            }}
                                        />
                                    </div>
                                </div>
                            ))}
                    </div>
                );
            case 4:
                return (
                    <div>
                        <h4 className="text-purpleB90 mt20 mb15">
                            {activeStep}. Do any plants need to be replaced on
                            your next visit?
                        </h4>
                        <p>Please make a selection.</p>
                        <div>
                            <FormControl component="fieldset">
                                <RadioGroup
                                    value={
                                        this.state.plantReplacements || 'false'
                                    }
                                    onChange={(e) =>
                                        this.setState({
                                            plantReplacements: e.target.value,
                                        })
                                    }
                                >
                                    <FormControlLabel
                                        value={'true'}
                                        control={<Radio />}
                                        label={
                                            <span>
                                                <div className="text-black">
                                                    <b>Yes</b>
                                                </div>
                                            </span>
                                        }
                                    />
                                    <FormControlLabel
                                        value={'false'}
                                        control={<Radio />}
                                        label={
                                            <span>
                                                <div className="text-black">
                                                    <b>No</b>
                                                </div>
                                            </span>
                                        }
                                    />
                                </RadioGroup>
                            </FormControl>
                        </div>
                        {this.state.plantReplacements === 'true' &&
                            this.state.plantReplacementList.map(
                                (replacementPlant, i) => (
                                    <div key={i}>
                                        <div className="flex flex-space-between flex-center-y">
                                            <p>
                                                <b>Plant {i + 1}</b>
                                            </p>
                                            <div className="flex flex-center-y">
                                                <p
                                                    className="text-small text-purpleB"
                                                    onClick={() =>
                                                        this.addRow(
                                                            'plant replacement',
                                                        )
                                                    }
                                                >
                                                    <span className="flex flex-center-y">
                                                        <Icon>add</Icon>
                                                    </span>
                                                </p>
                                                {this.state.plantReplacementList
                                                    .length > 1 && (
                                                    <p
                                                        className="text-small text-purpleB"
                                                        onClick={() =>
                                                            this.removeRow(
                                                                i,
                                                                'plant replacement',
                                                            )
                                                        }
                                                    >
                                                        <span className="flex flex-center-y">
                                                            <Icon>
                                                                delete_outline
                                                            </Icon>
                                                        </span>
                                                    </p>
                                                )}
                                            </div>
                                        </div>
                                        <div>
                                            <label>Plant Name</label>
                                            <input
                                                placeholder="Tomato"
                                                value={
                                                    replacementPlant['name'] ||
                                                    ''
                                                }
                                                onChange={async (e) =>
                                                    this.setRowValue({
                                                        index: i,
                                                        name: 'name',
                                                        value: e.target.value,
                                                        row: 'plant replacement',
                                                    })
                                                }
                                            />
                                        </div>
                                        <div>
                                            <label>Qty</label>
                                            <input
                                                type="number"
                                                placeholder="0"
                                                value={
                                                    replacementPlant['qty'] || 0
                                                }
                                                onChange={async (e) => {
                                                    const qty = await setQty(
                                                        replacementPlant['qty'],
                                                        e.target.value,
                                                    );
                                                    this.setRowValue({
                                                        index: i,
                                                        name: 'qty',
                                                        value: qty,
                                                        row: 'plant replacement',
                                                    });
                                                }}
                                            />
                                        </div>
                                    </div>
                                ),
                            )}
                        {this.state.plantReplacements === 'true' && (
                            <div className="mt15">
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={
                                                this.state.setReminder || false
                                            }
                                        />
                                    }
                                    label={'Add a reminder to pick up plants'}
                                    onClick={() =>
                                        this.setState({
                                            setReminder:
                                                !this.state.setReminder,
                                        })
                                    }
                                />
                            </div>
                        )}
                    </div>
                );
            case 5:
                return (
                    <div>
                        <h4 className="text-purpleB90 mt20 mb15">
                            {activeStep}. What is the current watering schedule?
                        </h4>
                        <p>Please make a selection.</p>
                        <div>
                            <FormControl component="fieldset">
                                <RadioGroup
                                    value={
                                        this.state.wateringScheduleDays ||
                                        'daily'
                                    }
                                    onChange={(e) =>
                                        this.setState({
                                            wateringScheduleDays:
                                                e.target.value,
                                        })
                                    }
                                >
                                    <FormControlLabel
                                        value={'daily'}
                                        control={<Radio />}
                                        label={
                                            <span>
                                                <div className="text-black">
                                                    <b>Daily</b>
                                                </div>
                                            </span>
                                        }
                                    />
                                    <FormControlLabel
                                        value={'every 2 days'}
                                        control={<Radio />}
                                        label={
                                            <span>
                                                <div className="text-black">
                                                    <b>Every 2 days</b>
                                                </div>
                                            </span>
                                        }
                                    />
                                    <FormControlLabel
                                        value={'every 3 days'}
                                        control={<Radio />}
                                        label={
                                            <span>
                                                <div className="text-black">
                                                    <b>Every 3 days</b>
                                                </div>
                                            </span>
                                        }
                                    />
                                    <FormControlLabel
                                        value={'every 4 days'}
                                        control={<Radio />}
                                        label={
                                            <span>
                                                <div className="text-black">
                                                    <b>Every 4 days</b>
                                                </div>
                                            </span>
                                        }
                                    />
                                    <FormControlLabel
                                        value={'every 5 days'}
                                        control={<Radio />}
                                        label={
                                            <span>
                                                <div className="text-black">
                                                    <b>Every 5 days</b>
                                                </div>
                                            </span>
                                        }
                                    />
                                </RadioGroup>
                            </FormControl>
                        </div>
                        <div>
                            <label>Minutes</label>
                            <input
                                type="number"
                                placeholder="5"
                                value={this.state.wateringScheduleMinutes || 0}
                                onChange={async (e) => {
                                    const qty = await setQty(
                                        this.state.wateringScheduleMinutes,
                                        e.target.value,
                                    );
                                    this.setState({
                                        wateringScheduleMinutes: qty,
                                    });
                                }}
                            />
                        </div>
                    </div>
                );
            case 6:
                return (
                    <div>
                        <h4 className="text-purpleB90 mt20 mb15">
                            {activeStep}. Please provide a note to the customer
                        </h4>
                        <p>
                            Tell the customer what you did during this garden
                            service.
                        </p>
                        <p>
                            Pro Tip: Use your voice-to-text feature to quickly
                            write detailed messages to the customer.
                        </p>
                        <div>
                            <label>Note</label>
                            <textarea
                                placeholder="Hello John, today I harvested 10 lbs of tomatoes..."
                                value={this.state.customerNote}
                                onChange={(e) =>
                                    this.setState({
                                        customerNote: e.target.value,
                                    })
                                }
                            />
                        </div>
                    </div>
                );
            case 7:
                return (
                    <div>
                        <h4 className="text-purpleB90 mt20 mb15">
                            {activeStep}. Upload images
                        </h4>
                        <p>Upload images of the garden.</p>
                        <div
                            onClick={() =>
                                document.getElementById('image-upload').click()
                            }
                            style={{
                                height: 200,
                                border: '2px dashed #EEEEEE',
                            }}
                            className="flex flex-center curved pointer"
                        >
                            <div>
                                <div className="flex flex-center">
                                    <Icon style={{ color: 'var(--purpleB)' }}>
                                        file_upload
                                    </Icon>
                                </div>
                                <div className="flex flex-center">
                                    <p className="mt5">
                                        {this.state.orderImagesList.length > 0
                                            ? `${
                                                  this.state.orderImagesList
                                                      .length
                                              } image${
                                                  this.state.orderImagesList
                                                      .length > 1
                                                      ? 's'
                                                      : ''
                                              } selected`
                                            : 'Click here to upload'}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <input
                            multiple
                            id="image-upload"
                            type="file"
                            className="filepicker invisible"
                            ref={(inp) => (this.selectedFiles = inp)}
                            onChange={() => this.addToOrderImagesList()}
                        />
                        <div className="flex flex-space-between mt15">
                            <div></div>
                            <p
                                className="text-small text-purpleB"
                                onClick={() =>
                                    this.setState({ orderImagesList: [] })
                                }
                            >
                                <span className="flex flex-center-y">
                                    Reset <Icon>restart_alt</Icon>
                                </span>
                            </p>
                        </div>
                    </div>
                );
            case 8:
                return (
                    <div>
                        <h4 className="text-purpleB90 mt20 mb15">
                            {activeStep}. Submit report
                        </h4>
                        <p>Review all reported results and submit.</p>
                        <div>
                            <p className="text-small">
                                <b>Dead plants</b>
                            </p>
                            {this.state.deadPlants === 'true' ? (
                                this.state.deadPlantList.map(
                                    (deadPlant, index) => (
                                        <div key={index}>
                                            <p className="text-small">
                                                ({deadPlant.qty}){' '}
                                                {deadPlant.name}
                                            </p>
                                        </div>
                                    ),
                                )
                            ) : (
                                <div>
                                    <p className="text-small">None</p>
                                </div>
                            )}
                        </div>
                        <Divider />
                        <div>
                            <p className="text-small">
                                <b>Harvested plants</b>
                            </p>
                            {this.state.harvestedPlants === 'true' ? (
                                this.state.harvestedPlantList.map(
                                    (harvestedPlant, index) => (
                                        <div key={index}>
                                            <p className="text-small">
                                                {harvestedPlant.name},{' '}
                                                {harvestedPlant.qty} lbs
                                            </p>
                                        </div>
                                    ),
                                )
                            ) : (
                                <div>
                                    <p className="text-small">None</p>
                                </div>
                            )}
                        </div>
                        <Divider />
                        <div>
                            <p className="text-small">
                                <b>New plants started today</b>
                            </p>
                            {this.state.newPlants === 'true' ? (
                                this.state.newPlantList.map(
                                    (newPlant, index) => (
                                        <div key={index}>
                                            <p className="text-small">
                                                ({newPlant.qty}) {newPlant.name}
                                            </p>
                                        </div>
                                    ),
                                )
                            ) : (
                                <div>
                                    <p className="text-small">None</p>
                                </div>
                            )}
                        </div>
                        <Divider />
                        <div>
                            <p className="text-small">
                                <b>Plants to be replaced next service</b>
                            </p>
                            {this.state.plantReplacements === 'true' ? (
                                this.state.plantReplacementList.map(
                                    (plantReplacement, index) => (
                                        <div key={index}>
                                            <p className="text-small">
                                                ({plantReplacement.qty}){' '}
                                                {plantReplacement.name}
                                            </p>
                                        </div>
                                    ),
                                )
                            ) : (
                                <div>
                                    <p className="text-small">None</p>
                                </div>
                            )}
                        </div>
                        <Divider />
                        <div>
                            <p className="text-small">
                                <b>Watering schedule</b>
                            </p>
                            <div>
                                <p className="text-small">
                                    Every {this.state.wateringScheduleDays} day
                                    {this.state.wateringScheduleDays !== 'daily'
                                        ? 's'
                                        : ''}{' '}
                                    for {this.state.wateringScheduleMinutes}{' '}
                                    minutes
                                </p>
                            </div>
                        </div>
                        <Divider />
                        <div>
                            <p className="text-small">
                                <b>Note to customer</b>
                            </p>
                            <div>
                                <p className="text-small">
                                    {this.state.customerNote}
                                </p>
                            </div>
                        </div>
                        <Divider />
                        <div>
                            <p className="text-small">
                                <b>Total photos</b>
                            </p>
                            <p className="text-small">
                                {this.state.orderImagesList.length}
                            </p>
                        </div>
                        <Divider />
                    </div>
                );
            default:
                return <div></div>;
        }
    }

    renderStep() {
        switch (this.props.order.type) {
            case YARD_ASSESSMENT:
                return this.renderYardAssessmentQuestions();
            case INSTALLATION:
                return this.renderNewGardenBedsSteps();
            case REVIVE:
                return this.renderNewGardenBedsSteps();
            case FULL_PLAN:
                return this.renderMaintenancePlanSteps();
            case MISC:
                return this.renderMiscServiceSteps();
            case SOIL_REFILL:
                return this.renderMiscServiceSteps();
            default:
                return <div></div>;
        }
    }

    renderProgressIndicator() {
        return (
            <div>
                <h4 className="text-purpleB90 mt20 mb15">Uploading results</h4>
                <p>
                    Your results are uploading, please wait for the upload to
                    complete.
                </p>
                {this.state.imagesUploaded && (
                    <p className="flex flex-center-y">
                        <Icon
                            style={{
                                color: 'var(--green0)',
                                marginRight: '10px',
                            }}
                        >
                            done
                        </Icon>
                        <b>Images uploaded</b>
                    </p>
                )}
                {this.state.createdInstallationBid && (
                    <p className="flex flex-center-y">
                        <Icon
                            style={{
                                color: 'var(--green0)',
                                marginRight: '10px',
                            }}
                        >
                            done
                        </Icon>
                        <b>Installation quote created</b>
                    </p>
                )}
                {this.state.sentCustomerQuote && (
                    <p className="flex flex-center-y">
                        <Icon
                            style={{
                                color: 'var(--green0)',
                                marginRight: '10px',
                            }}
                        >
                            done
                        </Icon>
                        <b>Installation quote sent to customer for approval</b>
                    </p>
                )}
                {this.state.createdReviveBid && (
                    <p className="flex flex-center-y">
                        <Icon
                            style={{
                                color: 'var(--green0)',
                                marginRight: '10px',
                            }}
                        >
                            done
                        </Icon>
                        <b>Revive quote created</b>
                    </p>
                )}
                {this.state.sentYardenReviveQuote && (
                    <p className="flex flex-center-y">
                        <Icon
                            style={{
                                color: 'var(--green0)',
                                marginRight: '10px',
                            }}
                        >
                            done
                        </Icon>
                        <b>Revive quote sent to Yarden for completion</b>
                    </p>
                )}
                {this.state.createdOtherServicesBid && (
                    <p className="flex flex-center-y">
                        <Icon
                            style={{
                                color: 'var(--green0)',
                                marginRight: '10px',
                            }}
                        >
                            done
                        </Icon>
                        <b>Misc quote created</b>
                    </p>
                )}
                {this.state.sentYardenOtherServicesQuote && (
                    <p className="flex flex-center-y">
                        <Icon
                            style={{
                                color: 'var(--green0)',
                                marginRight: '10px',
                            }}
                        >
                            done
                        </Icon>
                        <b>Misc quote sent to Yarden for completion</b>
                    </p>
                )}
                {this.state.chargedPayment && (
                    <p className="flex flex-center-y">
                        <Icon
                            style={{
                                color: 'var(--green0)',
                                marginRight: '10px',
                            }}
                        >
                            done
                        </Icon>
                        <b>Garden club member charged for labor payment</b>
                    </p>
                )}
                {this.state.createdInitialPlantingOrder && (
                    <p className="flex flex-center-y">
                        <Icon
                            style={{
                                color: 'var(--green0)',
                                marginRight: '10px',
                            }}
                        >
                            done
                        </Icon>
                        <b>Initial planting order created</b>
                    </p>
                )}
                {this.state.createdMaintenanceOrder && (
                    <p className="flex flex-center-y">
                        <Icon
                            style={{
                                color: 'var(--green0)',
                                marginRight: '10px',
                            }}
                        >
                            done
                        </Icon>
                        <b>Next maintenance order created</b>
                    </p>
                )}
                {this.state.sentCustomerResults && (
                    <p className="flex flex-center-y">
                        <Icon
                            style={{
                                color: 'var(--green0)',
                                marginRight: '10px',
                            }}
                        >
                            done
                        </Icon>
                        <b>Order results sent to customer</b>
                    </p>
                )}
                {this.state.createdPlantReplacementReminder && (
                    <p className="flex flex-center-y">
                        <Icon
                            style={{
                                color: 'var(--green0)',
                                marginRight: '10px',
                            }}
                        >
                            done
                        </Icon>
                        <b>
                            Plant replacement reminder created for next service
                        </b>
                    </p>
                )}
                {this.state.uploadComplete && (
                    <div className="flex flex-center mt15">
                        <button
                            onClick={() => window.location.reload()}
                            className="btn3 small flex flex-center-y"
                        >
                            <Icon>close</Icon>{' '}
                            <span style={{ marginLeft: 8 }}>Close</span>
                        </button>
                    </div>
                )}
            </div>
        );
    }

    calculateTotalSteps() {
        let totalSteps = 0;
        if (this.props.order.type === YARD_ASSESSMENT) {
            totalSteps = 3;
            if (this.state.gardenInstallation) totalSteps += 1;
            if (this.state.gardenRevive) totalSteps += 1;
            if (this.state.otherService) totalSteps += 1;
        } else if (
            this.props.order.type === INSTALLATION ||
            this.props.order.type === REVIVE
        ) {
            totalSteps = 4;
        } else if (this.props.order.type === FULL_PLAN) {
            totalSteps = 8;
        } else if (
            this.props.order.type === MISC ||
            this.props.order.type === SOIL_REFILL
        ) {
            totalSteps = 2;
        }

        return totalSteps;
    }

    render() {
        const { alertIsOpen, activeStep, uploadingResults } = this.state;

        const { isOpen = false, onClose, error } = this.props;

        // get total steps
        const totalSteps = this.calculateTotalSteps();

        return (
            <span>
                {/* alert modal */}
                <Alert
                    isOpen={alertIsOpen}
                    message={error.message}
                    onClose={() => this.setState({ alertIsOpen: false })}
                />

                {/* upload modal */}
                <Dialog
                    fullScreen={true}
                    fullWidth={true}
                    onClose={() => onClose(true)}
                    open={isOpen}
                    aria-labelledby="form-dialog-title"
                >
                    <DialogContent>
                        <div>
                            {/* progress indicator */}
                            {uploadingResults && this.renderProgressIndicator()}

                            {/* step indicator */}
                            {!uploadingResults && (
                                <StepIndicatorV2
                                    currentStep={activeStep}
                                    maxSteps={totalSteps}
                                />
                            )}

                            {/* step content */}
                            {!uploadingResults && this.renderStep()}

                            {/* next & back buttons */}
                            {!uploadingResults && (
                                <div className="flex flex-center flex-space-between mt25 mb25">
                                    <button
                                        onClick={() => this.back()}
                                        className="btn3 small flex flex-center-y"
                                    >
                                        <Icon>
                                            {activeStep === 1
                                                ? 'close'
                                                : 'arrow_back'}
                                        </Icon>{' '}
                                        <span style={{ marginLeft: 8 }}>
                                            {activeStep === 1
                                                ? 'Close'
                                                : 'Back'}
                                        </span>
                                    </button>
                                    {activeStep === totalSteps && (
                                        <button
                                            className="purple small flex flex-center-y"
                                            onClick={() => this.uploadImages()}
                                        >
                                            <Icon>done</Icon>{' '}
                                            <span style={{ marginLeft: 8 }}>
                                                Finish
                                            </span>
                                        </button>
                                    )}
                                    {activeStep !== totalSteps && (
                                        <button
                                            onClick={() => this.next()}
                                            className="btn3 small flex flex-center-y"
                                        >
                                            <span style={{ marginRight: 8 }}>
                                                Next
                                            </span>
                                            <Icon>arrow_forward</Icon>{' '}
                                        </button>
                                    )}
                                </div>
                            )}
                        </div>
                    </DialogContent>
                </Dialog>
            </span>
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.user,
        rules: state.rule,
        loading: state.loading,
        changeOrders: state.changeOrder,
        error: state.error,
        plans: state.plan,
        products: state.product,
        irrigation: state.irrigation,
        shapes: state.shape,
        drafts: state.draft,
        beds: state.bed,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            sendEmail,
            sendSms,
            createSubscription,
            updateUser,
            updateOrder,
            updateImage,
            getImage,
            createOrder,
            getTemplate,
            createBid,
            chargeCard,
            getRules,
            createJob,
            createReport,
            getReportType,
            getQuestions,
            getQuestionTypes,
            createTask,
            toggleLoading,
            getPlans,
            getProducts,
            getAccount,
            getIrrigation,
            getDrafts,
            createDraft,
            getBeds,
            createPlantList,
            getSpecialRequest,
            createSpecialRequest,
        },
        dispatch,
    );
}

UploadModal = connect(mapStateToProps, mapDispatchToProps)(UploadModal);

export default UploadModal;
