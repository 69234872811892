// libs
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

// actions
import { mapDispatchToProps } from '../actions/Dispatcher.js';

// UI components
import Icon from '@material-ui/core/Icon';
import ModeCommentOutlinedIcon from '@material-ui/icons/ModeCommentOutlined';
import LoaderModal from '../components/LoaderModal';
import Slider from '../components/UI/Slider';
import Link from '../components/UI/Link';
import Filepicker from '../components/UI/Filepicker';
import Plants from '../components/Plants';
import CustomerInfo from '../components/CustomerInfo';
import StepButtons from '../components/StepButtons';
import GeneralMessage from '../components/GeneralMessage';
import Navbar from '../components/Navbar';

// helpers
import getSeason from '../helpers/getSeason';
import convertOuncesToPounds from '../helpers/convertOuncesToPounds';
import logInfo from '../helpers/logInfo';

// formatting
import capitalize from '../formatting/capitalize';
import convertInchesToFeet from '../formatting/convertInchesToFeet';

// vars
import {
    ANNUAL,
    VEGETABLE,
    CULINARY_HERB,
    FRUIT,
    PLANTED,
    HARVESTED,
    DECEASED,
    FULL_HARVEST,
    PARTIAL_HARVEST,
    HEAD,
    ORDER_COMPLETE,
    FULL_PLAN,
    SOIL_ANALYSIS,
    SOIL_REFILL,
    ORDER_PENDING,
    BID_PENDING_APPROVAL,
} from '../vars/types';
import { PHONE_NUMBER } from '../vars/constants';
import vars from '../vars/index';

// styles
import '../css/maintenance-report.css';

const season = getSeason();
const DEAD_PLANTS = 'dead plants';
const NEW_PLANTS = 'new plants';
const HARVESTED_PLANTS = 'harvested plants';

let MaintenanceReport = ({
    createOrder,
    getOrder,
    checkOrders,
    checkBids,
    updateOrder,
    getBeds,
    getPlantCommonTypes,
    getQuestions,
    getReportType,
    createReport,
    createAnswer,
    createPlantActivity,
    getReports,
    getAnswers,
    updateBed,
    getImage,
    updateImage,
    getReschedules,
    getConversations,
    createConversation,
    createMessage,
    sendEmail,
    sendSms,
    sendAlert,
    getPlantSelection,
    beds,
    plantCommonTypes,
    plantSelection,
    questions,
    user,
}) => {
    const [loadingModalIsOpen, setLoadingModalIsOpen] = useState(true);
    const [activeStep, setActiveStep] = useState(1);
    const [invalidUrl, setInvalidUrl] = useState(false);
    const [selectedOrder, setSelectedOrder] = useState(null);
    const [reportType, setReportType] = useState(null);
    const [reportDeadPlants, setReportDeadPlants] = useState(false);
    const [reportNewPlants, setReportNewPlants] = useState(false);
    const [reportHarvestedPlants, setReportHarvestedPlants] = useState(false);
    const [reportNeemOil, setReportNeemOil] = useState(false);
    const [selectedBed, setSelectedBed] = useState(null);
    const [deadPlants, setDeadPlants] = useState([]);
    const [newPlants, setNewPlants] = useState([]);
    const [harvestedPlants, setHarvestedPlants] = useState([]);
    const [harvestLocation, setHarvestLocation] = useState('');
    const [soilRefillNeeded, setSoilRefillNeeded] = useState(false);
    const [powderyMildew, setPowderyMildew] = useState(false);
    const [pests, setPests] = useState(false);
    const [preventativeMaintenance, setPreventativeMaintenance] =
        useState(false);
    const [daysInterval, setDaysInterval] = useState(0);
    const [daysOfWeekInterval, setDaysOfWeekInterval] = useState([]);
    const [customIntervals, setCustomIntervals] = useState(false);
    const [minuteDuration, setMinuteDuration] = useState(0);
    const [previousWateringSchedule, setPreviousWateringSchedule] =
        useState(null);
    const [note, setNote] = useState('');
    const [images, setImages] = useState([]);
    const [reportSubmitted, setReportSubmitted] = useState(false);
    const [reportPreviouslySubmitted, setReportPreviouslySubmitted] =
        useState(false);
    const [hasPendingSoilOrder, setHasPendingSoilOrder] = useState(false);
    const [hasPendingSoilBid, setHasPendingSoilBid] = useState(false);
    const [error, setError] = useState(false);
    const urlParams = Object.fromEntries(
        new URLSearchParams(window.location.search),
    );

    useEffect(() => {
        const initData = async () => {
            if (urlParams.order) {
                setLoadingModalIsOpen(true);

                try {
                    const order = await getOrder(urlParams.order);
                    if (order.data.status === ORDER_COMPLETE) {
                        setLoadingModalIsOpen(false);
                        return setReportPreviouslySubmitted(true);
                    }

                    setSelectedOrder(order.data);

                    const maintenanceReportType = await getReportType(
                        `name=${order.data.type}`,
                    );

                    setReportType(maintenanceReportType.data);

                    await getQuestions(
                        `report_type=${maintenanceReportType.data._id}`,
                    );

                    const reports = await getReports(
                        `customer=${order.data.customer._id}&type=${maintenanceReportType.data._id}`,
                    );

                    let latestReport = null;
                    let newestDate = null;
                    for (const report of reports.data) {
                        const date = new Date(report.order.date);
                        if (!newestDate || date > newestDate) {
                            latestReport = report;
                            newestDate = date;
                        }
                    }

                    if (latestReport) {
                        const answers = await getAnswers(
                            `report=${latestReport._id}`,
                        );

                        if (answers.data.length > 0) {
                            const wateringSchedule = answers.data.find(
                                (answer) => answer.question.placement === 5,
                            );

                            if (wateringSchedule) {
                                setPreviousWateringSchedule(
                                    wateringSchedule.result,
                                );
                            }
                        }
                    }

                    const pendingSoilAnalysisOrder = await checkOrders(
                        `customer=${order.data.customer._id}&status=${ORDER_PENDING}&type=${SOIL_ANALYSIS}`,
                    );

                    const pendingSoilRefillOrder = await checkOrders(
                        `customer=${order.data.customer._id}&status=${ORDER_PENDING}&type=${SOIL_REFILL}`,
                    );

                    const pendingSoilOrders =
                        pendingSoilAnalysisOrder || pendingSoilRefillOrder;

                    if (pendingSoilOrders) {
                        setHasPendingSoilOrder(true);
                    }

                    const pendingSoilRefillBids = await checkBids(
                        `customer=${order.data.customer._id}&type=${SOIL_REFILL}&status=${BID_PENDING_APPROVAL}`,
                    );

                    if (pendingSoilRefillBids) {
                        setHasPendingSoilBid(true);
                    }

                    await getBeds(`customer=${order.data.customer._id}`);

                    await getPlantSelection(order.data.customer._id);

                    await getPlantCommonTypes();

                    logInfo('Maintenance Report page loaded', {
                        user: user.data,
                    });
                } catch (err) {
                    setError(true);
                }
            } else {
                setInvalidUrl(true);
            }

            setLoadingModalIsOpen(false);
        };

        initData();
    }, []);

    function next() {
        const validationMessage = validateInputs();
        if (validationMessage) {
            return window.alert(validationMessage);
        } else {
            let increment = 1;
            if (activeStep === 1 && !reportDeadPlants) {
                increment += 1;
            } else if (activeStep === 3 && !reportHarvestedPlants) {
                increment += 2;
            } else if (activeStep === 6 && !reportNewPlants) {
                increment += 1;
            }

            setActiveStep(activeStep + increment);
        }
    }

    function back() {
        let decrement = 1;
        if (activeStep === 3 && !reportDeadPlants) {
            decrement += 1;
        } else if (activeStep === 6 && !reportHarvestedPlants) {
            decrement += 2;
        } else if (activeStep === 8 && !reportNewPlants) {
            decrement += 1;
        }

        if (activeStep - decrement === 0) {
            if (
                window.confirm(
                    'Go back? You will lose any data that has already been entered.',
                )
            ) {
                window.location.href = `${window.location.origin}/dashboard`;
            }
        } else {
            setActiveStep(activeStep - decrement);
        }
    }

    function validateInputs() {
        let validationMessage = '';
        if (activeStep === 2 && deadPlants.length === 0) {
            validationMessage = 'Please select at least 1 dead plant';
        } else if (activeStep === 4 && harvestedPlants.length === 0) {
            validationMessage = 'Please select at least 1 harvested plant';
        } else if (activeStep === 7 && newPlants.length === 0) {
            validationMessage = 'Please select at least 1 new plant';
        } else if (
            activeStep === 9 &&
            reportNeemOil &&
            !powderyMildew &&
            !pests &&
            !preventativeMaintenance
        ) {
            validationMessage = 'select at least 1 reason';
        } else if (activeStep === 10) {
            if (minuteDuration === 0) {
                validationMessage = 'Please select a minute duration';
            } else if (!customIntervals && daysInterval === 0) {
                validationMessage = 'Please select at least 1 day';
            } else if (customIntervals && daysOfWeekInterval.length === 0) {
                validationMessage = 'Please select at least 1 day';
            }
        } else if (activeStep === 11 && !note) {
            validationMessage = 'Please add a note';
        }

        return validationMessage;
    }

    async function finish() {
        if (!images || images.length < 2) {
            return window.alert('Please upload at least 2 images');
        }

        setLoadingModalIsOpen(true);

        try {
            const newReport = await createReport({
                type: reportType._id,
                order: selectedOrder._id,
                customer: selectedOrder.customer._id,
            });
            const reportAnswers = [
                {
                    report: newReport.data._id,
                    question: setQuestion(1)._id,
                    result: reportDeadPlants,
                },
                {
                    report: newReport.data._id,
                    question: setQuestion(2)._id,
                    result: reportHarvestedPlants,
                },
                {
                    report: newReport.data._id,
                    question: setQuestion(3)._id,
                    result: reportNewPlants,
                },
                {
                    report: newReport.data._id,
                    question: setQuestion(4)._id,
                    result: reportNeemOil
                        ? {
                              powdery_mildew: powderyMildew,
                              pests: pests,
                              preventative_maintenance: preventativeMaintenance,
                          }
                        : {
                              powdery_mildew: false,
                              pests: false,
                              preventative_maintenance: false,
                          },
                },
                {
                    report: newReport.data._id,
                    question: setQuestion(5)._id,
                    result: customIntervals
                        ? {
                              minute_duration: minuteDuration,
                              custom_intervals: {
                                  monday: daysOfWeekInterval.some(
                                      (dayOfWeek) => dayOfWeek === 'Monday',
                                  ),
                                  tuesday: daysOfWeekInterval.some(
                                      (dayOfWeek) => dayOfWeek === 'Tuesday',
                                  ),
                                  wednesday: daysOfWeekInterval.some(
                                      (dayOfWeek) => dayOfWeek === 'Wednesday',
                                  ),
                                  thursday: daysOfWeekInterval.some(
                                      (dayOfWeek) => dayOfWeek === 'Thursday',
                                  ),
                                  friday: daysOfWeekInterval.some(
                                      (dayOfWeek) => dayOfWeek === 'Friday',
                                  ),
                                  saturday: daysOfWeekInterval.some(
                                      (dayOfWeek) => dayOfWeek === 'Saturday',
                                  ),
                                  sunday: daysOfWeekInterval.some(
                                      (dayOfWeek) => dayOfWeek === 'Sunday',
                                  ),
                              },
                          }
                        : {
                              minute_duration: minuteDuration,
                              days_interval: daysInterval,
                          },
                },
                {
                    report: newReport.data._id,
                    question: setQuestion(6)._id,
                    result: note,
                },
            ];
            let createAnswers = [];
            reportAnswers.forEach((data) => {
                createAnswers.push(
                    new Promise(async (resolve) => {
                        await createAnswer({
                            report: data.report,
                            question: data.question,
                            result: data.result,
                        });
                        resolve();
                    }),
                );
            });

            await Promise.all(createAnswers);

            let plantsToRemove = [];
            let createDeadPlantActivities = [];
            deadPlants.forEach((deadPlant) => {
                plantsToRemove.push(deadPlant);
                for (let i = 0; i < deadPlant.qty; i++) {
                    createDeadPlantActivities.push(
                        new Promise(async (resolve) => {
                            await createPlantActivity({
                                type: DECEASED,
                                owner: selectedOrder.vendor._id,
                                customer: selectedOrder.customer._id,
                                order: selectedOrder._id,
                                plant: deadPlant.plant,
                                key: i,
                                bed: deadPlant.bed,
                            });
                            resolve();
                        }),
                    );
                }
            });

            await Promise.all(createDeadPlantActivities);

            let createHarvestedPlantActivities = [];
            harvestedPlants.forEach((harvestedPlant, index) => {
                if (harvestedPlant.lifecycleType === FULL_HARVEST) {
                    const plantToRemoveIndex = plantsToRemove.findIndex(
                        (p) => p.plant === harvestedPlant.plant,
                    );
                    if (plantToRemoveIndex !== -1) {
                        plantsToRemove[plantToRemoveIndex].qty +=
                            harvestedPlant.qty;
                    } else {
                        plantsToRemove.push({
                            plant: harvestedPlant.plant,
                            bed: harvestedPlant.bed,
                            qty: harvestedPlant.qty,
                            category: harvestedPlant.category,
                        });
                    }
                }

                createHarvestedPlantActivities.push(
                    new Promise(async (resolve) => {
                        await createPlantActivity({
                            harvest: harvestedPlant.lifecycleType,
                            head: harvestedPlant.harvestType === HEAD,
                            qty: harvestedPlant.qty,
                            type: HARVESTED,
                            owner: selectedOrder.vendor._id,
                            customer: selectedOrder.customer._id,
                            order: selectedOrder._id,
                            plant: harvestedPlant.plant,
                            key: index,
                            bed: harvestedPlant.bed,
                        });
                        resolve();
                    }),
                );
            });

            await Promise.all(createHarvestedPlantActivities);

            let createNewPlantActivities = [];
            newPlants.forEach((newPlant) => {
                for (let i = 0; i < newPlant.qty; i++) {
                    createNewPlantActivities.push(
                        new Promise(async (resolve) => {
                            await createPlantActivity({
                                type: PLANTED,
                                owner: selectedOrder.vendor._id,
                                customer: selectedOrder.customer._id,
                                order: selectedOrder._id,
                                plant: newPlant.plant,
                                key: i,
                                bed: newPlant.bed,
                            });
                            resolve();
                        }),
                    );
                }
            });

            await Promise.all(createNewPlantActivities);

            let updatedBeds = [...beds.data];
            updatedBeds.forEach((bed) => {
                bed.vegetables = bed.vegetables.map((v) => ({
                    plant: v.plant._id,
                    category: v.plant.primary.category.name,
                    qty: v.qty,
                }));
                bed.herbs = bed.herbs.map((h) => ({
                    plant: h.plant._id,
                    category: h.plant.primary.category.name,
                    qty: h.qty,
                }));
                bed.fruit = bed.fruit.map((f) => ({
                    plant: f.plant._id,
                    category: f.plant.primary.category.name,
                    qty: f.qty,
                }));
            });

            if (plantsToRemove.length > 0) {
                const vegetablesToRemove = plantsToRemove.filter(
                    (plantToRemove) => plantToRemove.category === VEGETABLE,
                );
                const herbsToRemove = plantsToRemove.filter(
                    (plantToRemove) => plantToRemove.category === CULINARY_HERB,
                );
                const fruitToRemove = plantsToRemove.filter(
                    (plantToRemove) => plantToRemove.category === FRUIT,
                );

                updatedBeds.forEach((bed) => {
                    const updatedVegetables = removePlantsFromBed(
                        bed._id,
                        bed.vegetables,
                        vegetablesToRemove,
                    );
                    const updatedHerbs = removePlantsFromBed(
                        bed._id,
                        bed.herbs,
                        herbsToRemove,
                    );
                    const updatedFruit = removePlantsFromBed(
                        bed._id,
                        bed.fruit,
                        fruitToRemove,
                    );
                    bed.vegetables = updatedVegetables;
                    bed.herbs = updatedHerbs;
                    bed.fruit = updatedFruit;
                });
            }

            if (newPlants.length > 0) {
                const vegetablesToAdd = newPlants.filter(
                    (newPlant) => newPlant.category === VEGETABLE,
                );
                const herbsToAdd = newPlants.filter(
                    (newPlant) => newPlant.category === CULINARY_HERB,
                );
                const fruitToAdd = newPlants.filter(
                    (newPlant) => newPlant.category === FRUIT,
                );
                updatedBeds.forEach((bed) => {
                    const updatedVegetables = addPlantsToBed(
                        bed._id,
                        bed.vegetables,
                        vegetablesToAdd,
                    );
                    const updatedHerbs = addPlantsToBed(
                        bed._id,
                        bed.herbs,
                        herbsToAdd,
                    );
                    const updatedFruit = addPlantsToBed(
                        bed._id,
                        bed.fruit,
                        fruitToAdd,
                    );
                    bed.vegetables = updatedVegetables;
                    bed.herbs = updatedHerbs;
                    bed.fruit = updatedFruit;
                });
            }
            let updateBeds = [];
            updatedBeds.forEach((bed) => {
                updateBeds.push(
                    new Promise(async (resolve) => {
                        await updateBed(bed._id, {
                            vegetables: bed.vegetables,
                            herbs: bed.herbs,
                            fruit: bed.fruit,
                        });
                        resolve();
                    }),
                );
            });

            await Promise.all(updateBeds);

            let uploadImages = [];
            for (let file in images) {
                uploadImages.push(
                    new Promise(async (resolve) => {
                        const uploadedImage = await uploadImage(images[file]);
                        resolve(uploadedImage);
                    }),
                );
            }

            Promise.all(uploadImages).then(async (uploadedImages) => {
                await updateOrder(selectedOrder._id, {
                    images: uploadedImages,
                    status: ORDER_COMPLETE,
                });

                const orderDate =
                    selectedOrder.type === FULL_PLAN
                        ? moment(selectedOrder.date)
                              .add(1, 'week')
                              .startOf('day')
                        : moment(selectedOrder.date)
                              .add(2, 'weeks')
                              .startOf('day');
                const orderDescription =
                    selectedOrder.type === FULL_PLAN
                        ? vars.orderDescriptions.fullPlan
                        : vars.orderDescriptions.assistedPlan;

                let nextMaintenanceOrder = {
                    type: selectedOrder.type,
                    date: orderDate,
                    customer: selectedOrder.customer._id,
                    vendor: selectedOrder.vendor._id,
                    description: orderDescription,
                };

                const reschedules = await getReschedules(
                    `order=${selectedOrder._id}`,
                );

                if (reschedules.length > 0) {
                    const sortedReschedules = reschedules.sort(
                        (a, b) => new Date(b.date) - new Date(a.date),
                    );
                    const reschedule = sortedReschedules[0];
                    nextMaintenanceOrder.date = reschedule.date;
                }

                await createOrder(nextMaintenanceOrder);

                let message = {
                    sender: selectedOrder.vendor._id,
                    receiver: selectedOrder.customer._id,
                    text: note,
                    attachments: uploadedImages,
                    conversation_id: null,
                };

                if (soilRefillNeeded) {
                    const soilAnalysisOrder = {
                        type: SOIL_ANALYSIS,
                        date: moment(selectedOrder.date)
                            .add(1, 'week')
                            .startOf('day'),
                        customer: selectedOrder.customer._id,
                        description: vars.orderDescriptions.soilAnalysis,
                    };

                    await createOrder(soilAnalysisOrder);

                    await sendAlert({
                        channel: 'soil-notifications',
                        text: `*New Soil Notification!* \nGARDENER\n${capitalize(
                            selectedOrder.vendor.first_name,
                        )} ${capitalize(
                            selectedOrder.vendor.last_name,
                        )}\n\nMEMBER\n${capitalize(
                            selectedOrder.customer.first_name,
                        )} ${capitalize(
                            selectedOrder.customer.last_name,
                        )}\n\nNOTE\nThe soil level for this garden is too low, a new Soil Analysis order has been created to determine the amount of soil & amendments needed to restore the levels back to normal. Please assign a vendor to complete this task. `,
                    });
                }

                const conversations = await getConversations(
                    `users=${selectedOrder.customer._id},${selectedOrder.vendor._id}`,
                    true,
                );

                if (conversations.data.length > 0) {
                    message.conversation_id = conversations.data[0]._id;
                    await createMessage(message);
                } else {
                    const conversation = {
                        users: [
                            selectedOrder.customer._id,
                            selectedOrder.vendor._id,
                        ],
                    };

                    const newConversation =
                        await createConversation(conversation);
                    message.conversation_id = newConversation.data._id;
                    await createMessage(message);
                }

                if (reportHarvestedPlants) {
                    let imagesHTML = '';
                    let harvestedPlantsImages = '';
                    uploadedImages.forEach((image) => {
                        imagesHTML += `<div style="margin-top: 15px;"><img src="${image.url}" height="100" width="100" /></div>`;
                        harvestedPlantsImages += `${image.url}\n`;
                    });

                    const emailNotification = {
                        email: selectedOrder.customer.email,
                        subject: `Yarden - New Harvest`,
                        label: 'Harvest Results',
                        body:
                            `<p style="margin-bottom: 15px">Greetings from Yarden!</p>` +
                            `<p style="margin-bottom: 15px">Your gardener harvested some plants during their recent visit, see details below.</p>` +
                            '<div style="border-top: 1px solid #DDDDDD;">' +
                            '<div style="margin-top: 25px;">' +
                            '<p><b>Harvest Location</b></p>' +
                            `<p>${harvestLocation}</p>` +
                            '</div>' +
                            '<div style="margin-top: 25px;">' +
                            '<p><b>Note from gardener</b></p>' +
                            `<p>${note}</p>` +
                            '</div>' +
                            '<div style="margin-top: 25px;">' +
                            '<p><b>Photos</p></p>' +
                            imagesHTML +
                            '</div>' +
                            '</div>',
                    };

                    const maintenanceResultsUrl = `${window.location.origin}/maintenance-results?order=${selectedOrder._id}`;
                    const smsNotification = {
                        from: PHONE_NUMBER,
                        to: selectedOrder.customer.phone_number.replace(
                            /\D/g,
                            '',
                        ),
                        body: `Greetings from Yarden! Your gardener harvested some plants during their recent visit. Here is where they left your harvest: "${harvestLocation}". Use this link to view the note / photos from the gardener: ${maintenanceResultsUrl}`,
                    };

                    await sendEmail(emailNotification);
                    await sendSms(smsNotification);

                    let harvestedPlantsDescription = '';
                    let harvestedPlantsWeight = 0;
                    let harvestedPlantsValue = 0;
                    harvestedPlants.forEach((harvestedPlant) => {
                        let plant = plantCommonTypes.data.find(
                            (p) => p._id === harvestedPlant.plant,
                        );
                        if (plant) {
                            harvestedPlantsDescription += `(${
                                harvestedPlant.qty
                            }) ${capitalize(plant.name)}\n`;

                            const weight =
                                harvestedPlant.harvestType === HEAD
                                    ? plant.primary.average_head_weight
                                    : plant.primary.average_produce_weight;
                            const qty = harvestedPlant.qty;
                            const totalOunces = weight * qty;
                            const harvestedLbs =
                                convertOuncesToPounds(totalOunces);
                            harvestedPlantsWeight += harvestedLbs;
                            harvestedPlantsValue +=
                                plant.price_per_pound * harvestedLbs;
                        }
                    });

                    await sendAlert({
                        channel: 'harvests',
                        text: `*New Harvest!* \nGARDENER\n${capitalize(
                            selectedOrder.vendor.first_name,
                        )} ${capitalize(
                            selectedOrder.vendor.last_name,
                        )}\n\nMEMBER\n${capitalize(
                            selectedOrder.customer.first_name,
                        )} ${capitalize(
                            selectedOrder.customer.last_name,
                        )}\n\nNOTE\n"${note}"
                        \n\nPLANTS\n${harvestedPlantsDescription}
                        \n\nWEIGHT\n${harvestedPlantsWeight.toFixed(2)} (lbs)
                        \n\nRETAIL VALUE\n$${harvestedPlantsValue.toFixed(2)}
                        \n\nIMAGES\n${harvestedPlantsImages}`,
                    });
                }

                setReportSubmitted(true);
                setLoadingModalIsOpen(false);
            });
        } catch (err) {
            setError(true);
            setLoadingModalIsOpen(false);
        }
    }

    async function uploadImage(image) {
        const hostedImg = await getImage(image.name, image.type);
        const returnData = hostedImg.data.data.returnData;
        const signedRequest = returnData.signedRequest;
        const options = {
            headers: {
                'Content-Type': image.type,
            },
        };

        await updateImage(signedRequest, image, options);

        return {
            filename: image.name,
            mimetype: image.type,
            size: image.size,
            url: hostedImg.data.data.returnData.url,
        };
    }

    function removePlantsFromBed(bedId, currentPlantsForBed, plantsToRemove) {
        let updatedPlants = currentPlantsForBed
            .map((currentPlant) => {
                const plantToRemove = plantsToRemove.find(
                    (p) => p.plant === currentPlant.plant && p.bed === bedId,
                );
                if (plantToRemove) {
                    if (plantToRemove.qty < currentPlant.qty) {
                        return {
                            plant: currentPlant.plant,
                            qty: currentPlant.qty - plantToRemove.qty,
                        };
                    } else {
                        return undefined;
                    }
                } else {
                    return {
                        plant: currentPlant.plant,
                        qty: currentPlant.qty,
                    };
                }
            })
            .filter((data) => data !== undefined);

        return updatedPlants;
    }

    function addPlantsToBed(bedId, currentPlantsForBed, plantsToAdd) {
        let updatedPlants = [...currentPlantsForBed];
        plantsToAdd.forEach((newPlant) => {
            if (newPlant.bed === bedId) {
                let existingPlant = updatedPlants.find(
                    (currentPlant) => currentPlant.plant === newPlant.plant,
                );

                if (existingPlant) {
                    existingPlant.qty += newPlant.qty;
                } else {
                    updatedPlants.push({
                        plant: newPlant.plant,
                        qty: newPlant.qty,
                    });
                }
            }
        });

        return updatedPlants;
    }

    function updatePlants(plant, bedId, action, plantReportType) {
        let plants = [];
        if (plantReportType === DEAD_PLANTS) {
            plants = [...deadPlants];
        } else if (plantReportType === NEW_PLANTS) {
            plants = [...newPlants];
        } else if (plantReportType === HARVESTED_PLANTS) {
            plants = [...harvestedPlants];
        }

        const plantIndex = plants.findIndex(
            (p) => p.plant === plant._id && p.bed === bedId,
        );
        if (plantIndex !== -1) {
            if (action === 'add') {
                plants[plantIndex].qty = plants[plantIndex].qty + 1;
            } else if (action === 'subtract' && plants[plantIndex].qty !== 0) {
                if (plants[plantIndex].qty - 1 === 0) {
                    plants.splice(plantIndex, 1);
                } else {
                    plants[plantIndex].qty = plants[plantIndex].qty - 1;
                }
            }
        } else {
            if (action === 'add') {
                let plantHarvestType = {};
                let plantLifecycleType = {};
                if (plantReportType === HARVESTED_PLANTS) {
                    const harvestTypes = setHarvestTypes(plant.primary);
                    plantHarvestType = {
                        harvestType: harvestTypes[0],
                    };
                    plantLifecycleType = {
                        lifecycleType: PARTIAL_HARVEST,
                    };
                }

                plants.push({
                    qty: 1,
                    plant: plant._id,
                    category: plant.primary.category.name,
                    bed: bedId,
                    ...plantHarvestType,
                    ...plantLifecycleType,
                });
            }
        }

        if (plantReportType === DEAD_PLANTS) {
            setDeadPlants(plants);
        } else if (plantReportType === NEW_PLANTS) {
            setNewPlants(plants);
        } else if (plantReportType === HARVESTED_PLANTS) {
            setHarvestedPlants(plants);
        }
    }

    function calculateQty(plant, plantReportType) {
        let data = null;
        if (plantReportType === DEAD_PLANTS) {
            data = deadPlants.find(
                (d) => d.plant === plant._id && d.bed === selectedBed._id,
            );
        } else if (plantReportType === NEW_PLANTS) {
            data = newPlants.find(
                (n) => n.plant === plant._id && n.bed === selectedBed._id,
            );
        } else if (plantReportType === HARVESTED_PLANTS) {
            data = harvestedPlants.find(
                (h) => h.plant === plant._id && h.bed === selectedBed._id,
            );
        }

        const qty = data ? data.qty : 0;
        return qty;
    }

    function countMatchingPlants(allPlantsInBed, plantsBeingReported) {
        let matchCount = 0;
        allPlantsInBed.forEach((item1) => {
            plantsBeingReported.forEach((item2) => {
                if (item1.plant._id === item2.plant) {
                    matchCount++;
                }
            });
        });

        return matchCount;
    }

    function setPlants(plantReportType, plantCategory) {
        let plants = null;

        if (
            plantReportType === DEAD_PLANTS ||
            plantReportType === HARVESTED_PLANTS
        ) {
            if (plantCategory === VEGETABLE) {
                plants = selectedBed.vegetables;
            } else if (plantCategory === CULINARY_HERB) {
                plants = selectedBed.herbs;
            } else if (plantCategory === FRUIT) {
                plants = selectedBed.fruit;
            }
        } else {
            plants = plantCommonTypes.data
                .map((plantCommonType) => {
                    if (
                        plantCommonType.primary.category.name ===
                            plantCategory &&
                        (plantCommonType.primary.season === season ||
                            plantCommonType.primary.season === ANNUAL)
                    ) {
                        return {
                            plant: plantCommonType,
                        };
                    } else {
                        return undefined;
                    }
                })
                .filter((data) => data !== undefined);
        }

        return plants;
    }

    function setQuestion(placement) {
        const question = questions.data.find(
            (question) => question.placement === placement,
        );

        return question;
    }

    function formatWateringSchedule(wateringSchedule) {
        let scheduleDescription = '';

        if (wateringSchedule.days_interval) {
            scheduleDescription += `Every ${
                wateringSchedule.days_interval
            } day${wateringSchedule.days_interval > 1 ? 's' : ''}`;
        } else if (wateringSchedule.custom_intervals) {
            scheduleDescription += wateringSchedule.custom_intervals.monday
                ? ' Monday'
                : '';
            scheduleDescription += wateringSchedule.custom_intervals.tuesday
                ? ' Tuesday'
                : '';
            scheduleDescription += wateringSchedule.custom_intervals.wednesday
                ? ' Wednesday'
                : '';
            scheduleDescription += wateringSchedule.custom_intervals.thursday
                ? ' Thursday'
                : '';
            scheduleDescription += wateringSchedule.custom_intervals.friday
                ? ' Friday'
                : '';
            scheduleDescription += wateringSchedule.custom_intervals.saturday
                ? ' Saturday'
                : '';
            scheduleDescription += wateringSchedule.custom_intervals.sunday
                ? ' Sunday'
                : '';

            const separatedDays = scheduleDescription.split(' ');
            scheduleDescription = 'Every ';

            separatedDays.forEach((day, index) => {
                if (day) {
                    scheduleDescription += day;
                    if (index !== separatedDays.length - 1) {
                        scheduleDescription += ', ';
                    }
                }
            });
        }

        scheduleDescription += ` for ${wateringSchedule.minute_duration} minutes`;

        return scheduleDescription;
    }

    function setHarvestTypes(plantPrimary) {
        let harvestTypes = [];

        if (plantPrimary.average_produce_weight > 0) {
            harvestTypes.push(plantPrimary.produce_type.name);
        }

        if (plantPrimary.average_head_weight > 0) {
            harvestTypes.push(HEAD);
        }

        return harvestTypes;
    }

    function selectHarvestType(plantId, bedId, harvestType) {
        let plants = [...harvestedPlants];

        const plantIndex = plants.findIndex(
            (p) => p.plant === plantId && p.bed === bedId,
        );

        plants[plantIndex].harvestType = harvestType;

        setHarvestedPlants(plants);
    }

    function selectLifecycleType(plantId, bedId, lifecycleType) {
        let plants = [...harvestedPlants];

        const plantIndex = plants.findIndex(
            (p) => p.plant === plantId && p.bed === bedId,
        );

        plants[plantIndex].lifecycleType = lifecycleType;

        setHarvestedPlants(plants);
    }

    function renderStep1() {
        const question = setQuestion(1);
        return (
            <div>
                <p>{question.description}</p>
                <div className="radio-header">
                    <div className="flex-center-y">
                        <input
                            type="radio"
                            checked={reportDeadPlants}
                            onChange={() => setReportDeadPlants(true)}
                        />
                        <h5 className="ml4">Yes</h5>
                    </div>
                </div>
                <div className="radio-header mt15">
                    <div className="flex-center-y">
                        <input
                            type="radio"
                            checked={!reportDeadPlants}
                            onChange={() => setReportDeadPlants(false)}
                        />
                        <h5 className="ml4">No</h5>
                    </div>
                </div>
            </div>
        );
    }

    function renderStep2() {
        return (
            <div>
                {selectedBed ? (
                    <div>{renderBed(DEAD_PLANTS)}</div>
                ) : (
                    <div>
                        <p>Tap on each bed where you found dead plants</p>
                        {renderBeds()}
                    </div>
                )}
            </div>
        );
    }

    function renderStep3() {
        const question = setQuestion(2);
        return (
            <div>
                <p>{question.description}</p>
                <div className="radio-header">
                    <div className="flex-center-y">
                        <input
                            type="radio"
                            checked={reportHarvestedPlants}
                            onChange={() => setReportHarvestedPlants(true)}
                        />
                        <h5 className="ml4">Yes</h5>
                    </div>
                </div>
                <div className="radio-header mt15">
                    <div className="flex-center-y">
                        <input
                            type="radio"
                            checked={!reportHarvestedPlants}
                            onChange={() => setReportHarvestedPlants(false)}
                        />
                        <h5 className="ml4">No</h5>
                    </div>
                </div>
            </div>
        );
    }

    function renderStep4() {
        return (
            <div>
                {selectedBed ? (
                    <div>{renderBed(HARVESTED_PLANTS)}</div>
                ) : (
                    <div>
                        <p>Tap on each bed where you harvested plants</p>
                        {renderBeds()}
                    </div>
                )}
            </div>
        );
    }

    function renderStep5() {
        return (
            <div>
                <div className="flex flex-center mb5">
                    <Icon fontSize="large" style={{ color: 'var(--purpleB)' }}>
                        location_on_outline
                    </Icon>
                </div>
                <p>Where did you leave the harvested plants?</p>
                <textarea
                    placeholder="Next to the front door..."
                    rows={4}
                    value={harvestLocation}
                    onChange={(e) => setHarvestLocation(e.target.value)}
                />
            </div>
        );
    }

    function renderStep6() {
        const question = setQuestion(3);
        return (
            <div>
                <p>{question.description}</p>
                <div className="radio-header">
                    <div className="flex-center-y">
                        <input
                            type="radio"
                            checked={reportNewPlants}
                            onChange={() => setReportNewPlants(true)}
                        />
                        <h5 className="ml4">Yes</h5>
                    </div>
                </div>
                <div className="radio-header mt15">
                    <div className="flex-center-y">
                        <input
                            type="radio"
                            checked={!reportNewPlants}
                            onChange={() => setReportNewPlants(false)}
                        />
                        <h5 className="ml4">No</h5>
                    </div>
                </div>
            </div>
        );
    }

    function renderStep7() {
        return (
            <div>
                {selectedBed ? (
                    <div>{renderBed(NEW_PLANTS)}</div>
                ) : (
                    <div>
                        <p>Tap on each bed where you added new plants</p>
                        {renderBeds()}
                    </div>
                )}
            </div>
        );
    }

    function renderStep8() {
        const pendingAction = hasPendingSoilOrder || hasPendingSoilBid;
        return (
            <div>
                <p>
                    Do <u>all</u> current garden beds need more soil?
                </p>
                {pendingAction && (
                    <div className="pending-action-indicator p10 curved">
                        This garden was recently reported for low soil levels.
                        We are working on refilling the soil levels back to
                        normal.
                    </div>
                )}
                <div
                    style={{
                        filter: pendingAction ? 'blur(5px)' : 'none',
                        transition: 'filter 0.3s',
                    }}
                >
                    <small className="mb25">
                        Rule: If the soil level of all garden beds is more than
                        50% from the top, then select "Yes"
                    </small>
                    <ul>
                        <li>12" tall beds have 6" of soil or less</li>
                        <li>18" tall beds have 9" of soil or less</li>
                        <li>24" tall beds have 12" of soil or less</li>
                    </ul>
                    <div className="radio-header">
                        <div className="flex-center-y">
                            <input
                                type="radio"
                                checked={soilRefillNeeded}
                                onChange={() => setSoilRefillNeeded(true)}
                                disabled={pendingAction}
                            />
                            <h5 className="ml4">Yes</h5>
                        </div>
                    </div>
                    <div className="radio-header mt15">
                        <div className="flex-center-y">
                            <input
                                type="radio"
                                checked={!soilRefillNeeded}
                                onChange={() => setSoilRefillNeeded(false)}
                                disabled={pendingAction}
                            />
                            <h5 className="ml4">No</h5>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    function renderStep9() {
        const question = setQuestion(4);
        return (
            <div>
                <p>{question.description}</p>
                <div className="radio-header">
                    <div className="flex-center-y">
                        <input
                            type="radio"
                            checked={reportNeemOil}
                            onChange={() => setReportNeemOil(true)}
                        />
                        <h5 className="ml4">Yes</h5>
                    </div>
                </div>
                <div className="radio-header mt15">
                    <div className="flex-center-y">
                        <input
                            type="radio"
                            checked={!reportNeemOil}
                            onChange={() => setReportNeemOil(false)}
                        />
                        <h5 className="ml4">No</h5>
                    </div>
                </div>
                {reportNeemOil && (
                    <div className="mt15">
                        <hr />
                        <p>Select all reasons that apply</p>
                        <div>
                            <div className="flex flex-center-y mt15">
                                <input
                                    type="checkbox"
                                    checked={powderyMildew}
                                    onChange={() => {
                                        setPowderyMildew(!powderyMildew);
                                    }}
                                />
                                <label className="ml10">
                                    Powdery Mildew was present
                                </label>
                            </div>
                            <div className="flex flex-center-y mt15">
                                <input
                                    type="checkbox"
                                    checked={pests}
                                    onChange={() => {
                                        setPests(!pests);
                                    }}
                                />
                                <label className="ml10">
                                    Pests were present
                                </label>
                            </div>
                            <div className="flex flex-center-y mt15">
                                <input
                                    type="checkbox"
                                    checked={preventativeMaintenance}
                                    onChange={() => {
                                        setPreventativeMaintenance(
                                            !preventativeMaintenance,
                                        );
                                    }}
                                />
                                <label className="ml10">
                                    Preventative Maintenance
                                </label>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        );
    }

    function renderStep10() {
        const question = setQuestion(5);
        return (
            <div>
                <p>{question.description}</p>
                <label>PREVIOUS WATERING SCHEDULE</label>
                <div className="mb15">{renderPreviousWateringSchedule()}</div>
                {customIntervals
                    ? renderCustomIntervals()
                    : renderRegularIntervals()}
                <hr />
                <div className="flex flex-space-between mb15 mt25">
                    <h6>For...</h6>
                    {renderMinuteDurationControl()}
                </div>
                <Slider
                    min={0}
                    max={60}
                    step={1}
                    value={minuteDuration}
                    onChange={(value) => {
                        setMinuteDuration(value);
                    }}
                />
            </div>
        );
    }

    function renderStep11() {
        const question = setQuestion(6);
        return (
            <div>
                <div className="flex flex-center mb5">
                    <ModeCommentOutlinedIcon
                        fontSize="large"
                        style={{ color: 'var(--purpleB)' }}
                    />
                </div>
                <p>{question.description}</p>
                <textarea
                    placeholder="Captain's log..."
                    rows={4}
                    value={note}
                    onChange={(e) => setNote(e.target.value)}
                />
            </div>
        );
    }

    function renderStep12() {
        return (
            <div>
                <p>
                    Upload a minimum of 2 photos. If there was a harvest, make
                    sure to upload photos of the harvested plants.
                </p>
                <div className="mt10">
                    <Filepicker
                        max={10}
                        onChange={(files) => setImages(files)}
                        onReset={() => setImages([])}
                    />
                </div>
            </div>
        );
    }

    function renderPreviousWateringSchedule() {
        if (previousWateringSchedule) {
            return (
                <label>
                    {formatWateringSchedule(previousWateringSchedule)}
                </label>
            );
        } else {
            return <label>No previous watering schedule found</label>;
        }
    }

    function renderRegularIntervals() {
        return (
            <div>
                <div className="flex flex-space-between mb15">
                    <h6>Once every...</h6>
                    {renderDayIntervalControl()}
                </div>
                <Slider
                    min={0}
                    max={5}
                    step={1}
                    value={daysInterval}
                    onChange={(value) => {
                        setDaysInterval(value);
                    }}
                />
                <div className="flex flex-space-between mt15">
                    <div />
                    <Link
                        onClick={() => {
                            setDaysInterval(1);
                            setCustomIntervals(true);
                        }}
                    >
                        Custom
                    </Link>
                </div>
            </div>
        );
    }

    function renderCustomIntervals() {
        const daysOfWeek = [
            'Monday',
            'Tuesday',
            'Wednesday',
            'Thursday',
            'Friday',
            'Saturday',
            'Sunday',
        ];
        return (
            <div>
                <div className="flex flex-space-between mb15">
                    <h6>Once every...</h6>
                </div>
                {daysOfWeek.map((day, index) => (
                    <div className="flex flex-center-y mt15" key={index}>
                        <input
                            type="checkbox"
                            checked={
                                daysOfWeekInterval.find(
                                    (dayOfWeek) => dayOfWeek === day,
                                ) || false
                            }
                            onChange={() => {
                                const dayIndex = daysOfWeekInterval.findIndex(
                                    (dayOfWeek) => dayOfWeek === day,
                                );
                                let days = [...daysOfWeekInterval];
                                if (dayIndex === -1) {
                                    days.push(day);
                                } else {
                                    days.splice(dayIndex, 1);
                                }

                                setDaysOfWeekInterval(days);
                            }}
                        />
                        <label className="ml10">{day}</label>
                    </div>
                ))}
                <div className="flex flex-space-between mt15">
                    <div />
                    <Link onClick={() => setCustomIntervals(false)}>
                        Regular
                    </Link>
                </div>
            </div>
        );
    }

    function renderDayIntervalControl() {
        return (
            <div>
                <h6>
                    {daysInterval} {daysInterval > 1 ? 'Days' : 'Day'}
                </h6>
            </div>
        );
    }

    function renderMinuteDurationControl() {
        return (
            <div>
                <h6>
                    {minuteDuration}{' '}
                    {minuteDuration !== 1 ? 'Minutes' : 'Minute'}
                </h6>
            </div>
        );
    }

    function renderBadge(bed) {
        let plants = 0;
        const allPlantsInBed = [...bed.vegetables, ...bed.herbs, ...bed.fruit];
        if (activeStep === 2) {
            const matchingDeadPlants = countMatchingPlants(
                allPlantsInBed,
                deadPlants.filter((deadPlant) => deadPlant.bed === bed._id),
            );

            plants = matchingDeadPlants;
        } else if (activeStep === 4) {
            const matchingHarvestedPlants = countMatchingPlants(
                allPlantsInBed,
                harvestedPlants.filter(
                    (harvestedPlant) => harvestedPlant.bed === bed._id,
                ),
            );

            plants = matchingHarvestedPlants;
        } else if (activeStep === 7) {
            plants = newPlants.filter(
                (newPlant) => newPlant.bed === bed._id,
            ).length;
        }

        return (
            <div
                className="badge"
                style={{
                    background: plants > 0 ? 'var(--purpleB)' : 'var(--gray3)',
                }}
            >
                <data>{plants}</data>
            </div>
        );
    }

    function renderBeds() {
        let rows = [];
        let columns = [];
        beds.data.forEach((bed) => columns.push(bed));
        const size = 2;
        while (columns.length > 0) rows.push(columns.splice(0, size));

        return rows.map((row, i) => {
            return (
                <div key={i} className="bed-rows">
                    {row.map((column, index) => {
                        const bedId = column.key;
                        return (
                            <div
                                key={index}
                                className="flex flex-center-x"
                                style={{
                                    width: '45%',
                                }}
                            >
                                <div
                                    className="flex flex-space-between"
                                    style={{
                                        flexDirection: 'row',
                                    }}
                                >
                                    {renderBadge(column)}
                                    <div
                                        className="card mb25"
                                        onClick={() => setSelectedBed(column)}
                                    >
                                        <img
                                            alt="raised garden bed"
                                            src="https://yarden-garden.s3.us-west-1.amazonaws.com/mobile/raised-bed-01.png"
                                            className="garden-bed"
                                        />
                                        <div className="flex flex-center">
                                            <div>
                                                <div className="text-center">
                                                    <label>
                                                        {`Garden Bed #${bedId}`}
                                                    </label>
                                                </div>
                                                <div className="text-center">
                                                    <label>
                                                        {`${convertInchesToFeet(
                                                            column.width,
                                                        )}' X ${convertInchesToFeet(
                                                            column.length,
                                                        )}' X ${convertInchesToFeet(
                                                            column.height,
                                                        )}'`}
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            );
        });
    }

    function renderBed(plantReportType) {
        const vegetables = setPlants(plantReportType, VEGETABLE);
        const herbs = setPlants(plantReportType, CULINARY_HERB);
        const fruit = setPlants(plantReportType, FRUIT);

        return (
            <div>
                <h4>Garden Bed #{selectedBed.key}</h4>
                <p className="mt15">Select the {plantReportType}</p>
                <hr />
                <div className="overflow-y-scroll curved p15 mb25 bg-gray1 plant-selection-grid plants-container">
                    <p className="mt15 mb0">
                        <b>Vegetables</b>
                    </p>
                    {vegetables.length === 0 && 'None'}
                    {vegetables.map((vegetable, index) => {
                        const harvestTypes = setHarvestTypes(
                            vegetable.plant.primary,
                        );

                        const harvestedPlant = harvestedPlants.find(
                            (hp) =>
                                hp.plant === vegetable.plant._id &&
                                hp.bed === selectedBed._id,
                        );

                        const selectedHarvestType = harvestedPlant
                            ? harvestedPlant.harvestType
                            : null;

                        const selectedLifecycleType = harvestedPlant
                            ? harvestedPlant.lifecycleType
                            : null;

                        const qty = calculateQty(
                            vegetable.plant,
                            plantReportType,
                        );
                        return (
                            <div key={index}>
                                <div className="flex flex-space-between flex-center-y mb10">
                                    <div className="flex flex-center-y">
                                        <img
                                            alt="vegetable plant"
                                            src={vegetable.plant.image}
                                            width={35}
                                        />
                                        <label className="ml10 capitalize">
                                            {vegetable.plant.name}{' '}
                                            {plantReportType !== NEW_PLANTS
                                                ? `(${vegetable.qty})`
                                                : ''}
                                        </label>
                                    </div>
                                    <div className="flex flex-center-y">
                                        <h4 className="mb5">{qty}</h4>
                                        <div
                                            className="pl25"
                                            onClick={() =>
                                                updatePlants(
                                                    vegetable.plant,
                                                    selectedBed._id,
                                                    'subtract',
                                                    plantReportType,
                                                )
                                            }
                                        >
                                            <Icon>remove_outline</Icon>
                                        </div>
                                        <div
                                            className="pl25"
                                            onClick={() =>
                                                updatePlants(
                                                    vegetable.plant,
                                                    selectedBed._id,
                                                    'add',
                                                    plantReportType,
                                                )
                                            }
                                        >
                                            <Icon>add_outline</Icon>
                                        </div>
                                    </div>
                                </div>
                                {qty > 0 &&
                                    plantReportType === HARVESTED_PLANTS && (
                                        <div className="p10 curved harvested-plant-controls">
                                            {harvestTypes.length > 1 &&
                                                harvestTypes.map(
                                                    (harvestType, i) => (
                                                        <div
                                                            key={i}
                                                            className={`flex flex-center-y ${
                                                                i !== 0
                                                                    ? 'mt10'
                                                                    : ''
                                                            }`}
                                                        >
                                                            <input
                                                                type="radio"
                                                                className="mr10"
                                                                checked={
                                                                    selectedHarvestType ===
                                                                    harvestType
                                                                }
                                                                onChange={() => {
                                                                    selectHarvestType(
                                                                        vegetable
                                                                            .plant
                                                                            ._id,
                                                                        selectedBed._id,
                                                                        harvestType,
                                                                    );
                                                                }}
                                                            />
                                                            <label className="uppercase">
                                                                {selectedHarvestType ===
                                                                harvestType
                                                                    ? `(${qty}) `
                                                                    : ''}
                                                                {harvestType}
                                                            </label>
                                                        </div>
                                                    ),
                                                )}
                                            <div
                                                className={`${
                                                    harvestTypes.length > 1
                                                        ? 'mt10'
                                                        : ''
                                                }`}
                                            >
                                                <label>
                                                    Was this a full or partial
                                                    harvest?
                                                </label>
                                                <select
                                                    className="full-width"
                                                    value={
                                                        selectedLifecycleType ||
                                                        PARTIAL_HARVEST
                                                    }
                                                    onChange={(e) => {
                                                        selectLifecycleType(
                                                            vegetable.plant._id,
                                                            selectedBed._id,
                                                            e.target.value,
                                                        );
                                                    }}
                                                >
                                                    <option
                                                        value={PARTIAL_HARVEST}
                                                    >
                                                        Partial, plant(s) is
                                                        still producing
                                                    </option>
                                                    <option
                                                        value={FULL_HARVEST}
                                                    >
                                                        Full, plant(s) needs to
                                                        be replaced
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                    )}
                            </div>
                        );
                    })}
                    <p className="mt15 mb0">
                        <b>Herbs</b>
                    </p>
                    {herbs.length === 0 && 'None'}
                    {herbs.map((herb, index) => {
                        const harvestTypes = setHarvestTypes(
                            herb.plant.primary,
                        );

                        const harvestedPlant = harvestedPlants.find(
                            (hp) =>
                                hp.plant === herb.plant._id &&
                                hp.bed === selectedBed._id,
                        );

                        const selectedHarvestType = harvestedPlant
                            ? harvestedPlant.harvestType
                            : null;

                        const selectedLifecycleType = harvestedPlant
                            ? harvestedPlant.lifecycleType
                            : null;

                        const qty = calculateQty(herb.plant, plantReportType);
                        return (
                            <div key={index}>
                                <div className="flex flex-space-between flex-center-y mb10">
                                    <div className="flex flex-center-y">
                                        <img
                                            alt="herb plant"
                                            src={herb.plant.image}
                                            width={35}
                                        />
                                        <label className="ml10 capitalize">
                                            {herb.plant.name}{' '}
                                            {plantReportType !== NEW_PLANTS
                                                ? `(${herb.qty})`
                                                : ''}
                                        </label>
                                    </div>
                                    <div className="flex flex-center-y">
                                        <h4 className="mb5">{qty}</h4>
                                        <div
                                            className="pl25"
                                            onClick={() =>
                                                updatePlants(
                                                    herb.plant,
                                                    selectedBed._id,
                                                    'subtract',
                                                    plantReportType,
                                                )
                                            }
                                        >
                                            <Icon>remove_outline</Icon>
                                        </div>
                                        <div
                                            className="pl25"
                                            onClick={() =>
                                                updatePlants(
                                                    herb.plant,
                                                    selectedBed._id,
                                                    'add',
                                                    plantReportType,
                                                )
                                            }
                                        >
                                            <Icon>add_outline</Icon>
                                        </div>
                                    </div>
                                </div>
                                {qty > 0 &&
                                    plantReportType === HARVESTED_PLANTS && (
                                        <div className="p10 curved harvested-plant-controls">
                                            {harvestTypes.length > 1 &&
                                                harvestTypes.map(
                                                    (harvestType, i) => (
                                                        <div
                                                            key={i}
                                                            className={`flex flex-center-y ${
                                                                i !== 0
                                                                    ? 'mt10'
                                                                    : ''
                                                            }`}
                                                        >
                                                            <input
                                                                type="radio"
                                                                className="mr10"
                                                                checked={
                                                                    selectedHarvestType ===
                                                                    harvestType
                                                                }
                                                                onChange={() => {
                                                                    selectHarvestType(
                                                                        herb
                                                                            .plant
                                                                            ._id,
                                                                        selectedBed._id,
                                                                        harvestType,
                                                                    );
                                                                }}
                                                            />
                                                            <label className="uppercase">
                                                                {selectedHarvestType ===
                                                                harvestType
                                                                    ? `(${qty}) `
                                                                    : ''}
                                                                {harvestType}
                                                            </label>
                                                        </div>
                                                    ),
                                                )}
                                            <div
                                                className={`${
                                                    harvestTypes.length > 1
                                                        ? 'mt10'
                                                        : ''
                                                }`}
                                            >
                                                <label>
                                                    Was this a full or partial
                                                    harvest?
                                                </label>
                                                <select
                                                    className="full-width"
                                                    value={
                                                        selectedLifecycleType ||
                                                        PARTIAL_HARVEST
                                                    }
                                                    onChange={(e) => {
                                                        selectLifecycleType(
                                                            herb.plant._id,
                                                            selectedBed._id,
                                                            e.target.value,
                                                        );
                                                    }}
                                                >
                                                    <option
                                                        value={PARTIAL_HARVEST}
                                                    >
                                                        Partial, plant is still
                                                        producing
                                                    </option>
                                                    <option
                                                        value={FULL_HARVEST}
                                                    >
                                                        Full, plant needs to be
                                                        replaced
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                    )}
                            </div>
                        );
                    })}
                    <p className="mt15 mb0">
                        <b>Fruit</b>
                    </p>
                    {fruit.length === 0 && 'None'}
                    {fruit.map((fr, index) => {
                        const harvestTypes = setHarvestTypes(fr.plant.primary);

                        const harvestedPlant = harvestedPlants.find(
                            (hp) =>
                                hp.plant === fr.plant._id &&
                                hp.bed === selectedBed._id,
                        );

                        const selectedHarvestType = harvestedPlant
                            ? harvestedPlant.harvestType
                            : null;

                        const selectedLifecycleType = harvestedPlant
                            ? harvestedPlant.lifecycleType
                            : null;

                        const qty = calculateQty(fr.plant, plantReportType);
                        return (
                            <div key={index}>
                                <div className="flex flex-space-between flex-center-y mb10">
                                    <div className="flex flex-center-y">
                                        <img
                                            alt="fruit plant"
                                            src={fr.plant.image}
                                            width={35}
                                        />
                                        <label className="ml10 capitalize">
                                            {fr.plant.name}{' '}
                                            {plantReportType !== NEW_PLANTS
                                                ? `(${fr.qty})`
                                                : ''}
                                        </label>
                                    </div>
                                    <div className="flex flex-center-y">
                                        <h4 className="mb5">{qty}</h4>
                                        <div
                                            className="pl25"
                                            onClick={() =>
                                                updatePlants(
                                                    fr.plant,
                                                    selectedBed._id,
                                                    'subtract',
                                                    plantReportType,
                                                )
                                            }
                                        >
                                            <Icon>remove_outline</Icon>
                                        </div>
                                        <div
                                            className="pl25"
                                            onClick={() =>
                                                updatePlants(
                                                    fr.plant,
                                                    selectedBed._id,
                                                    'add',
                                                    plantReportType,
                                                )
                                            }
                                        >
                                            <Icon>add_outline</Icon>
                                        </div>
                                    </div>
                                </div>
                                {qty > 0 &&
                                    plantReportType === HARVESTED_PLANTS && (
                                        <div className="p10 curved harvested-plant-controls">
                                            {harvestTypes.length > 1 &&
                                                harvestTypes.map(
                                                    (harvestType, i) => (
                                                        <div
                                                            key={i}
                                                            className={`flex flex-center-y ${
                                                                i !== 0
                                                                    ? 'mt10'
                                                                    : ''
                                                            }`}
                                                        >
                                                            <input
                                                                type="radio"
                                                                className="mr10"
                                                                checked={
                                                                    selectedHarvestType ===
                                                                    harvestType
                                                                }
                                                                onChange={() => {
                                                                    selectHarvestType(
                                                                        fr.plant
                                                                            ._id,
                                                                        selectedBed._id,
                                                                        harvestType,
                                                                    );
                                                                }}
                                                            />
                                                            <label className="uppercase">
                                                                {selectedHarvestType ===
                                                                harvestType
                                                                    ? `(${qty}) `
                                                                    : ''}
                                                                {harvestType}
                                                            </label>
                                                        </div>
                                                    ),
                                                )}
                                            <div
                                                className={`${
                                                    harvestTypes.length > 1
                                                        ? 'mt10'
                                                        : ''
                                                }`}
                                            >
                                                <label>
                                                    Was this a full or partial
                                                    harvest?
                                                </label>
                                                <select
                                                    className="full-width"
                                                    value={
                                                        selectedLifecycleType ||
                                                        PARTIAL_HARVEST
                                                    }
                                                    onChange={(e) => {
                                                        selectLifecycleType(
                                                            fr.plant._id,
                                                            selectedBed._id,
                                                            e.target.value,
                                                        );
                                                    }}
                                                >
                                                    <option
                                                        value={PARTIAL_HARVEST}
                                                    >
                                                        Partial, plant is still
                                                        producing
                                                    </option>
                                                    <option
                                                        value={FULL_HARVEST}
                                                    >
                                                        Full, plant needs to be
                                                        replaced
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                    )}
                            </div>
                        );
                    })}
                </div>
                <button
                    className="btn3 full-width mt15"
                    onClick={() => setSelectedBed(null)}
                >
                    Done
                </button>
            </div>
        );
    }

    function renderMessage() {
        let message = '';
        let isSuccess = false;
        if (reportSubmitted) {
            message = 'Success! Your service report has been submitted.';
            isSuccess = true;
        } else if (invalidUrl) {
            message = 'Invalid URL, please contact support to continue.';
        } else if (reportPreviouslySubmitted) {
            message = 'You have already submitted a report for this order.';
        } else if (error) {
            message =
                'Uh oh! Something went wrong, we are working on a fix now.';
        }

        return (
            <GeneralMessage
                message={message}
                isSuccess={isSuccess}
                buttonText="Continue to dashboard"
                buttonAction={() => (window.location.pathname = '/dashboard')}
            />
        );
    }

    function renderView() {
        switch (activeStep) {
            case 1:
                return renderStep1();
            case 2:
                return renderStep2();
            case 3:
                return renderStep3();
            case 4:
                return renderStep4();
            case 5:
                return renderStep5();
            case 6:
                return renderStep6();
            case 7:
                return renderStep7();
            case 8:
                return renderStep8();
            case 9:
                return renderStep9();
            case 10:
                return renderStep10();
            case 11:
                return renderStep11();
            case 12:
                return renderStep12();
            default:
                return <></>;
        }
    }

    return (
        <div>
            {/* modals */}
            <LoaderModal isOpen={loadingModalIsOpen} />

            {plantSelection && plantSelection.data && (
                <Plants plantSelection={plantSelection.data} />
            )}

            {/* page header */}
            <Navbar />

            <div className="pt75 pb25 pl25 pr25">
                {/* page view */}
                {!invalidUrl &&
                    !reportSubmitted &&
                    !reportPreviouslySubmitted &&
                    !error &&
                    selectedOrder &&
                    questions.data && (
                        <div className="step-content">
                            <div className="mt25 text-center">
                                <h6>Maintenance Report</h6>
                                <CustomerInfo
                                    customer={selectedOrder.customer}
                                />
                            </div>
                            <div className="mt25">{renderView()}</div>
                            {!selectedBed && (
                                <div className="mt25">
                                    <StepButtons
                                        next={() => next()}
                                        back={() => back()}
                                        finish={() => finish()}
                                        isLastStep={activeStep === 12}
                                    />
                                </div>
                            )}
                        </div>
                    )}

                {/* message view */}
                {(reportSubmitted ||
                    invalidUrl ||
                    reportPreviouslySubmitted ||
                    error) &&
                    renderMessage()}
            </div>
        </div>
    );
};

function mapStateToProps(state) {
    return {
        user: state.user,
        beds: state.bed,
        plantCommonTypes: state.plantCommonType,
        questions: state.question,
        answers: state.answer,
        plantSelection: state.plantSelection,
    };
}

MaintenanceReport = connect(
    mapStateToProps,
    mapDispatchToProps,
)(MaintenanceReport);

export default MaintenanceReport;
