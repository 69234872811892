// libs
import React, { useState, useEffect } from 'react';

// styling
import '../css/hero-section.css';

// UI components
import Icon from '@material-ui/core/Icon';
import InstagramIcon from './InstagramIcon';
import FacebookIcon from './FacebookIcon';
import LinkedInIcon from './LinkedInIcon';
import XIcon from './XIcon';

// vars
import { APPOINTMENT_URL } from '../vars/constants';

// images
const heroImg =
    'https://yarden-garden.s3.us-west-1.amazonaws.com/homepage_images/hero-garden-bed.jpg';
const familyAndNature =
    'https://yarden-garden.s3.us-west-1.amazonaws.com/homepage_images/carousel_famiilyandnature.png';
const outdoorSpace =
    'https://yarden-garden.s3.us-west-1.amazonaws.com/homepage_images/carousel_outdoorspace.png';
const unleash =
    'https://yarden-garden.s3.us-west-1.amazonaws.com/homepage_images/carousel_unleash.png';

const slides = [
    {
        id: 0,
        headline: <span>Transform Your Outdoor&nbsp;Space</span>,
        text: 'At Yarden, we help you transform your outdoor space into a flourishing oasis with our comprehensive gardening services.',
        img: outdoorSpace,
    },
    {
        id: 1,
        headline: 'Rooted in Family and Nature:',
        text: 'Yarden empowers you to create shared moments and enduring memories. We foster a deep bond with nature through our focused approach to enriching your garden.',
        img: familyAndNature,
    },
    {
        id: 2,
        headline: 'Unleash Your Journey of Growth',
        text: 'Start an exceptional personal and botanical expedition with Yarden. Enjoy cultivating your food, endorse eco-friendly living, and cherish a verdant, more gratifying lifestyle.',
        img: unleash,
    },
];
const HeroSection = () => {
    const [currentSlide, setCurrentSlide] = useState(0);
    const [isFullscreen, setIsFullscreen] = useState(false);
    const nextSlide = () => {
        setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length);
    };

    const prevSlide = () => {
        setCurrentSlide(
            (prevSlide) => (prevSlide - 1 + slides.length) % slides.length,
        );
    };

    useEffect(() => {
        const slideInterval = setInterval(nextSlide, 10000);

        const checkFullScreen = () => {
            setIsFullscreen(
                document.fullscreenElement ||
                    document.webkitFullscreenElement ||
                    document.mozFullScreenElement ||
                    document.msFullscreenElement,
            );
        };

        document.addEventListener('fullscreenchange', checkFullScreen);
        document.addEventListener('webkitfullscreenchange', checkFullScreen);
        document.addEventListener('mozfullscreenchange', checkFullScreen);
        document.addEventListener('MSFullscreenChange', checkFullScreen);

        return () => {
            clearInterval(slideInterval);
            document.removeEventListener('fullscreenchange', checkFullScreen);
            document.removeEventListener(
                'webkitfullscreenchange',
                checkFullScreen,
            );
            document.removeEventListener(
                'mozfullscreenchange',
                checkFullScreen,
            );
            document.removeEventListener('MSFullscreenChange', checkFullScreen);
        };
    }, []);

    return (
        <section
            className="hero hero-section"
            style={{ marginTop: isFullscreen ? 'var(--unit6)' : '' }}
        >
            <div className="hero-left-panel">
                <ul className="left-panel-nav">
                    <li>
                        <a
                            href="https://www.youtube.com/watch?v=4rqe7nu4Lk0"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Testimonials
                        </a>
                    </li>
                    <li>
                        <a href="/careers">Careers</a>
                    </li>
                    <li>
                        <a
                            href="https://officialyarden.medium.com/"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Blog
                        </a>
                    </li>
                </ul>
                <ul className="left-panel-social">
                    <li>
                        <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://www.instagram.com/official_yarden"
                        >
                            <InstagramIcon height={24} />
                        </a>
                    </li>
                    <li>
                        <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://www.facebook.com/yardengardeninc"
                        >
                            <FacebookIcon height={24} />
                        </a>
                    </li>
                    <li>
                        <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://twitter.com/official_yarden"
                        >
                            <XIcon height={24} />
                        </a>
                    </li>
                    <li>
                        <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://www.linkedin.com/company/12903995"
                        >
                            <LinkedInIcon height={24} />
                        </a>
                    </li>
                </ul>
            </div>
            <h1 className="headline">Gardening. Reimagined.</h1>
            <div className="hero-img-container">
                <img src={heroImg} alt="hero" className="hero-img" />
            </div>
            <div className="carousel-container">
                <h5 className="slide-headline">
                    {slides[currentSlide].headline}
                </h5>
                <small className="slide-text mt10">
                    {slides[currentSlide].text}
                </small>
                <div className="slide-img-container">
                    {slides.map((slide, index) => (
                        <img
                            key={index}
                            className={`slide-img ${
                                currentSlide === index ? 'fade-in' : ''
                            }`}
                            src={slide.img}
                            alt="slide"
                            style={{
                                display:
                                    currentSlide === index ? 'block' : 'none',
                            }}
                        />
                    ))}

                    <div className="carousel-buttons">
                        <div className="slide-controls">
                            <button
                                onClick={prevSlide}
                                style={{ minWidth: 'unset' }}
                                className="left-arrow small purple"
                            >
                                &lt;
                            </button>
                            <button
                                onClick={nextSlide}
                                style={{ minWidth: 'unset' }}
                                className="right-arrow small purple"
                            >
                                &gt;
                            </button>
                        </div>
                        <button
                            onClick={() =>
                                (window.location.href = APPOINTMENT_URL)
                            }
                        >
                            <Icon>calendar_month</Icon>
                            <span style={{ marginLeft: 8 }}>Let's Talk</span>
                        </button>
                    </div>
                </div>
                <div className="slide-indicators">
                    {slides.map((slide, index) => (
                        <div
                            key={index}
                            className={`slide-indicator ${
                                index === currentSlide ? 'active' : ''
                            }`}
                        ></div>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default HeroSection;
