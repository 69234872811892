// libs
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

// actions
import { mapDispatchToProps } from '../actions/Dispatcher.js';

// UI components
import LoaderModal from '../components/LoaderModal';
import StepButtons from '../components/StepButtons';
import CustomerInfo from '../components/CustomerInfo';
import Navbar from '../components/Navbar';
import Filepicker from '../components/UI/Filepicker';
import GeneralMessage from '../components/GeneralMessage';

// formatting
import dimensions from '../formatting/dimensions';

// helpers
import getQuotePricing from '../helpers/getQuotePricing';
import generatePlotPoints from '../helpers/generatePlotPoints';

// vars
import { ORDER_COMPLETE, INITIAL_PLANTING } from '../vars/types';
import vars from '../vars/index';

// styles
import '../css/new-garden-beds-report.css';

let NewGardenBedsReport = ({
    getOrder,
    createOrder,
    updateOrder,
    getChangeOrders,
    chargeCard,
    getSpecialRequest,
    createSpecialRequest,
    createDraft,
    getImage,
    updateImage,
    getShapes,
    changeOrders,
    shapes,
}) => {
    const [loadingModalIsOpen, setLoadingModalIsOpen] = useState(true);
    const [activeStep, setActiveStep] = useState(1);
    const [invalidUrl, setInvalidUrl] = useState(false);
    const [selectedOrder, setSelectedOrder] = useState(null);
    const [bedToKeyMapping, setBedToKeyMapping] = useState([]);
    const [images, setImages] = useState([]);
    const [reportSubmitted, setReportSubmitted] = useState(false);
    const [reportPreviouslySubmitted, setReportPreviouslySubmitted] =
        useState(false);
    const [error, setError] = useState(false);
    const urlParams = Object.fromEntries(
        new URLSearchParams(window.location.search),
    );

    useEffect(() => {
        const initData = async () => {
            if (urlParams.order) {
                setLoadingModalIsOpen(true);

                try {
                    const order = await getOrder(urlParams.order);
                    if (order.data.status === ORDER_COMPLETE) {
                        setLoadingModalIsOpen(false);
                        return setReportPreviouslySubmitted(true);
                    }

                    await getChangeOrders(`order=${order.data._id}`);

                    await getShapes();

                    setSelectedOrder(order.data);

                    let allBeds = [];
                    order.data.bid.line_items.beds.forEach((bed) => {
                        for (let i = 0; i < bed.qty; i++) {
                            allBeds.push({
                                shape: bed.shape.name,
                                width: bed.width,
                                length: bed.length,
                                height: bed.height,
                                key: 0,
                            });
                        }
                    });

                    setBedToKeyMapping(allBeds);
                } catch (err) {
                    setError(true);
                }
            } else {
                setInvalidUrl(true);
            }

            setLoadingModalIsOpen(false);
        };

        initData();
    }, []);

    function next() {
        const validationMessage = validateInputs();
        if (validationMessage) {
            return window.alert(validationMessage);
        } else {
            let increment = 1;
            setActiveStep(activeStep + increment);
        }
    }

    function back() {
        let decrement = 1;
        if (activeStep - decrement === 0) {
            if (
                window.confirm(
                    'Go back? You will lose any data that has already been entered.',
                )
            ) {
                window.location.href = `${window.location.origin}/dashboard`;
            }
        } else {
            setActiveStep(activeStep - decrement);
        }
    }

    function updateBedKey(indexToUpdate, newValue) {
        setBedToKeyMapping((prevBedToKeyMapping) => {
            const updatedBedToKeyMapping = [...prevBedToKeyMapping];

            updatedBedToKeyMapping[indexToUpdate] = {
                ...updatedBedToKeyMapping[indexToUpdate],
                key: !newValue ? 0 : newValue,
            };

            return updatedBedToKeyMapping;
        });
    }

    async function chargePayment() {
        if (selectedOrder.bid) {
            let description = `Labor - ${selectedOrder.bid.description}`;
            let amount = 0;
            const quotePricing = getQuotePricing(selectedOrder.bid);
            amount += quotePricing.secondPayment.total;

            if (changeOrders.data.length > 0) {
                changeOrders.data.forEach((changeOrder) => {
                    if (
                        changeOrder.status === 'approved' &&
                        changeOrder.line_items &&
                        changeOrder.line_items.labor
                    ) {
                        description += `; ${changeOrder.description}`;
                        const changeOrderPricing = getQuotePricing(changeOrder);
                        amount += changeOrderPricing.secondPayment.total;
                    }
                });
            }

            const total = parseFloat(amount).toFixed(2) * 100;
            const payment = {
                amount: total,
                currency: 'usd',
                statement_descriptor_suffix: 'Labor',
                description: description,
                cardId: selectedOrder.customer.payment_info.card_id,
                customerId: selectedOrder.customer.payment_info.customer_id,
            };

            await chargeCard(payment, selectedOrder.customer);
        }
    }

    async function uploadImage(image) {
        const hostedImg = await getImage(image.name, image.type);
        const returnData = hostedImg.data.data.returnData;
        const signedRequest = returnData.signedRequest;
        const options = {
            headers: {
                'Content-Type': image.type,
            },
        };

        await updateImage(signedRequest, image, options);

        return {
            filename: image.name,
            mimetype: image.type,
            size: image.size,
            url: hostedImg.data.data.returnData.url,
        };
    }

    function validateInputs() {
        let validationMessage = '';
        if (activeStep === 1) {
            const bedsWithUnassignedKeys = bedToKeyMapping.some(
                (bed) => bed.key === 0,
            );
            if (bedsWithUnassignedKeys) {
                validationMessage = 'Please assign a number to each bed';
            }
        }

        return validationMessage;
    }

    async function finish() {
        if (!images || images.length < 2) {
            return window.alert('Please upload at least 2 images');
        }

        setLoadingModalIsOpen(true);

        try {
            await chargePayment();

            const newOrder = {
                type: INITIAL_PLANTING,
                date: moment().add(1, 'week').startOf('day'),
                customer: selectedOrder.customer._id,
                bid: selectedOrder.bid._id,
                point_of_sale: window.location.pathname,
                description: vars.orderDescriptions.initialPlanting,
            };

            const initialPlantingOrder = await createOrder(newOrder);

            let createDrafts = [];
            const rectangle = shapes.data.find(
                (shape) => shape.name === 'rectangle',
            );
            for (let i = 0; i < bedToKeyMapping.length; i++) {
                const measurements = 2;
                const rows = (bedToKeyMapping[i].length / 12) * measurements;
                const columns = (bedToKeyMapping[i].width / 12) * measurements;
                const plotPoints = await generatePlotPoints(rows, columns);

                const newDraft = {
                    key: parseInt(bedToKeyMapping[i].key),
                    customer: selectedOrder.customer._id,
                    order: initialPlantingOrder.data._id,
                    width: bedToKeyMapping[i].width,
                    length: bedToKeyMapping[i].length,
                    height: bedToKeyMapping[i].height,
                    shape: rectangle._id,
                    plot_points: plotPoints,
                };

                createDrafts.push(
                    new Promise(async (resolve) => {
                        await createDraft(newDraft);
                        resolve();
                    }),
                );
            }

            Promise.all(createDrafts);

            const specialRequest = await getSpecialRequest(
                `order=${selectedOrder._id}`,
            );
            if (specialRequest && specialRequest.data) {
                await createSpecialRequest({
                    order: initialPlantingOrder.data._id,
                    description: specialRequest.data.description,
                });
            }

            let uploadImages = [];
            for (let file in images) {
                uploadImages.push(
                    new Promise(async (resolve) => {
                        const uploadedImage = await uploadImage(images[file]);
                        resolve(uploadedImage);
                    }),
                );
            }

            Promise.all(uploadImages).then(async (uploadedImages) => {
                await updateOrder(selectedOrder._id, {
                    images: uploadedImages,
                    status: ORDER_COMPLETE,
                });

                setReportSubmitted(true);
                setLoadingModalIsOpen(false);
            });
        } catch (err) {
            setError(true);
            setLoadingModalIsOpen(false);
        }
    }

    function renderStep1() {
        return (
            <div>
                <p>What number did you assign to each garden bed?</p>
                {bedToKeyMapping.map((bed, i) => (
                    <div
                        className="flex flex-space-between flex-center-y mb15"
                        key={i}
                    >
                        <div>{dimensions(bed)}</div>
                        <div className="flex flex-center-y">
                            #
                            <input
                                type="number"
                                className="bed-key-input ml5"
                                value={bed.key}
                                onChange={(e) =>
                                    updateBedKey(
                                        i,
                                        e.target.value.replace(/^0+/, ''),
                                    )
                                }
                            />
                        </div>
                    </div>
                ))}
            </div>
        );
    }

    function renderStep2() {
        return (
            <div>
                <p>Upload a minimum of 2 photos</p>
                <div className="mt10">
                    <Filepicker
                        max={10}
                        onChange={(files) => setImages(files)}
                        onReset={() => setImages([])}
                    />
                </div>
            </div>
        );
    }

    function renderMessage() {
        let message = '';
        let isSuccess = false;
        if (reportSubmitted) {
            message = 'Success! Your service report has been submitted.';
            isSuccess = true;
        } else if (invalidUrl) {
            message = 'Invalid URL, please contact support to continue.';
        } else if (reportPreviouslySubmitted) {
            message = 'You have already submitted a report for this order.';
        } else if (error) {
            message =
                'Uh oh! Something went wrong, we are working on a fix now.';
        }

        return (
            <GeneralMessage
                message={message}
                isSuccess={isSuccess}
                buttonText="Continue to dashboard"
                buttonAction={() => (window.location.pathname = '/dashboard')}
            />
        );
    }

    function renderView() {
        switch (activeStep) {
            case 1:
                return renderStep1();
            case 2:
                return renderStep2();
            default:
                return <></>;
        }
    }

    return (
        <div>
            {/* modals */}
            <LoaderModal isOpen={loadingModalIsOpen} />

            {/* page header */}
            <Navbar />

            <div className="pt75 pb25 pl25 pr25">
                {/* page view */}
                {!invalidUrl &&
                    !reportSubmitted &&
                    !reportPreviouslySubmitted &&
                    !error &&
                    selectedOrder && (
                        <div className="step-content">
                            <div className="mt25 text-center">
                                <h6 className="capitalize">
                                    {selectedOrder.type} Report
                                </h6>
                                {
                                    <CustomerInfo
                                        customer={selectedOrder.customer}
                                    />
                                }
                            </div>
                            <div className="mt25">{renderView()}</div>
                            <div className="mt25">
                                <StepButtons
                                    next={() => next()}
                                    back={() => back()}
                                    finish={() => finish()}
                                    isLastStep={activeStep === 2}
                                />
                            </div>
                        </div>
                    )}

                {/* message view */}
                {(reportSubmitted ||
                    invalidUrl ||
                    reportPreviouslySubmitted ||
                    error) &&
                    renderMessage()}
            </div>
        </div>
    );
};

function mapStateToProps(state) {
    return {
        user: state.user,
        changeOrders: state.changeOrder,
        shapes: state.shape,
    };
}

NewGardenBedsReport = connect(
    mapStateToProps,
    mapDispatchToProps,
)(NewGardenBedsReport);

export default NewGardenBedsReport;
