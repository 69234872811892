// libraries
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment';

// UI components
import Icon from '@material-ui/core/Icon';
import EditOutlined from '@material-ui/icons/EditOutlined';
import CloudUploadOutlined from '@material-ui/icons/CloudUploadOutlined';
import CommentOutlined from '@material-ui/icons/CommentOutlined';
import DateTimeModal from './DateTimeModal';
import ChangeOrderModal from './ChangeOrderModal';
import DescriptionModal from './DescriptionModal';
import UploadModal from './UploadModal';

// actions
import { createOrder, updateOrder } from '../actions/order/index';

// helpers
import convertInchesToFeet from '../formatting/convertInchesToFeet';
import combineBeds from '../helpers/combineBeds';
import { formatNumber } from '../formatting/phone';

// vars
import vars from '../vars/index.js';
import {
    INSTALLATION,
    REVIVE,
    FULL_PLAN,
    ASSISTED_PLAN,
    CROP_ROTATION,
    INITIAL_PLANTING,
    YARD_ASSESSMENT,
    SOIL_ANALYSIS,
    GARDENER,
    PARTNER,
} from '../vars/types';

// style
import '../css/order-details.css';

class OrderDetails extends Component {
    state = {};

    calculatePayout(changeOrders) {
        let payout = 0;
        const order = this.props.selectedOrder;

        if (order) {
            if (order.type === 'misc') {
                if (order.bid.line_items.accessories) {
                    order.bid.line_items.accessories.forEach((accessory) => {
                        if (
                            accessory.name ===
                            vars.accessories.fivePotGarden.title.toLowerCase()
                        )
                            payout +=
                                accessory.qty *
                                vars.payouts.accessories.fivePotGarden;
                        if (
                            accessory.name ===
                            vars.accessories.gardenCage.title.toLowerCase()
                        )
                            payout +=
                                accessory.qty *
                                vars.payouts.accessories.gardenCage;
                        if (
                            accessory.name ===
                            vars.accessories.trellis.title.toLowerCase()
                        )
                            payout +=
                                accessory.qty *
                                vars.payouts.accessories.trellis;
                        if (
                            accessory.name ===
                            vars.accessories.coverBox.title.toLowerCase()
                        )
                            payout +=
                                accessory.qty *
                                vars.payouts.accessories.coverBox;
                        if (
                            accessory.name ===
                            vars.accessories.cloche.title.toLowerCase()
                        )
                            payout +=
                                accessory.qty * vars.payouts.accessories.cloche;
                    });
                } else if (
                    order.bid &&
                    order.bid.line_items &&
                    order.bid.line_items.labor
                ) {
                    payout =
                        order.bid.line_items.labor.price *
                            order.bid.line_items.labor.qty -
                        order.bid.line_items.labor.price *
                            order.bid.line_items.labor.qty *
                            vars.fees.misc;
                } else {
                    return 'No record found';
                }
            } else if (order.type === 'installation') {
                if (order.bid && order.bid.line_items) {
                    if (order.bid.line_items.beds) {
                        order.bid.line_items.beds.forEach((bed) => {
                            if (bed.height === 12)
                                payout +=
                                    bed.qty * vars.payouts.installation.twelve;
                            if (bed.height === 18)
                                payout +=
                                    bed.qty *
                                    vars.payouts.installation.eighteen;
                            if (bed.height === 24)
                                payout +=
                                    bed.qty *
                                    vars.payouts.installation.twentyFour;
                        });
                    }

                    if (order.bid.line_items.accessories) {
                        order.bid.line_items.accessories.forEach(
                            (accessory) => {
                                if (
                                    accessory.name ===
                                    vars.accessories.fivePotGarden.title.toLowerCase()
                                )
                                    payout +=
                                        accessory.qty *
                                        vars.payouts.accessories.fivePotGarden;
                                if (
                                    accessory.name ===
                                    vars.accessories.gardenCage.title.toLowerCase()
                                )
                                    payout +=
                                        accessory.qty *
                                        vars.payouts.accessories.gardenCage;
                                if (
                                    accessory.name ===
                                    vars.accessories.trellis.title.toLowerCase()
                                )
                                    payout +=
                                        accessory.qty *
                                        vars.payouts.accessories.trellis;
                                if (
                                    accessory.name ===
                                    vars.accessories.coverBox.title.toLowerCase()
                                )
                                    payout +=
                                        accessory.qty *
                                        vars.payouts.accessories.coverBox;
                                if (
                                    accessory.name ===
                                    vars.accessories.cloche.title.toLowerCase()
                                )
                                    payout +=
                                        accessory.qty *
                                        vars.payouts.accessories.cloche;
                            },
                        );
                    }
                } else {
                    return 'No record found';
                }
            } else if (order.type === 'revive') {
                if (
                    order.bid &&
                    order.bid.line_items &&
                    order.bid.line_items.beds
                ) {
                    order.bid.line_items.beds.forEach((bed) => {
                        if (bed.height === 12)
                            payout +=
                                convertInchesToFeet(bed.width) *
                                convertInchesToFeet(bed.length) *
                                vars.payouts.revive.twelve *
                                bed.qty;
                        if (bed.height === 18)
                            payout +=
                                convertInchesToFeet(bed.width) *
                                convertInchesToFeet(bed.length) *
                                vars.payouts.revive.eighteen *
                                bed.qty;
                        if (bed.height === 24)
                            payout +=
                                convertInchesToFeet(bed.width) *
                                convertInchesToFeet(bed.length) *
                                vars.payouts.revive.twentyFour *
                                bed.qty;
                    });
                } else {
                    return 'No record found';
                }
            } else if (order.type === YARD_ASSESSMENT) {
                payout = vars.payouts.yardAssessment;
            } else if (
                order.type === FULL_PLAN ||
                order.type === ASSISTED_PLAN
            ) {
                payout = vars.payouts.maintenance;
            } else if (order.type === INITIAL_PLANTING) {
                if (order.bid && order.bid.line_items) {
                    if (order.bid.line_items.beds) {
                        order.bid.line_items.beds.forEach((bed) => {
                            payout +=
                                convertInchesToFeet(bed.width) *
                                convertInchesToFeet(bed.length) *
                                vars.payouts.initialPlanting *
                                bed.qty;
                        });
                    }
                }
            } else if (order.type === CROP_ROTATION) {
                if (this.props.beds) {
                    const beds = combineBeds(this.props.beds.data);
                    beds.forEach((bed) => {
                        payout +=
                            convertInchesToFeet(bed.width) *
                            convertInchesToFeet(bed.length) *
                            vars.payouts.cropRotation *
                            bed.qty;
                    });
                }
            }
        }

        if (changeOrders && changeOrders.length > 0) {
            changeOrders.forEach((changeOrder) => {
                if (
                    changeOrder.status === 'approved' &&
                    changeOrder.line_items.labor
                ) {
                    payout +=
                        changeOrder.line_items.labor.price *
                            changeOrder.line_items.labor.qty -
                        changeOrder.line_items.labor.price *
                            changeOrder.line_items.labor.qty *
                            vars.fees.misc;
                }
            });
        }

        return `$${payout.toFixed(2)}`;
    }

    render() {
        const {
            dateTimeModalIsOpen,
            uploadModalIsOpen,
            changeOrderModalIsOpen,
            descriptionModalIsOpen,
        } = this.state;

        const {
            user,
            users,
            changeOrders,
            selectedOrder,
            onUpdateOrder,
            specialRequest,
        } = this.props;

        if (selectedOrder) {
            return (
                <div>
                    {/* modals */}
                    <DateTimeModal
                        isOpen={dateTimeModalIsOpen}
                        order={selectedOrder}
                        close={() =>
                            this.setState({ dateTimeModalIsOpen: false })
                        }
                        onChange={() => onUpdateOrder()}
                    />

                    <UploadModal
                        isOpen={uploadModalIsOpen}
                        order={selectedOrder}
                        onClose={async (closedByUser) => {
                            if (!closedByUser) await onUpdateOrder();
                            this.setState({ uploadModalIsOpen: false });
                        }}
                    />

                    <ChangeOrderModal
                        isOpen={changeOrderModalIsOpen}
                        onClose={() =>
                            this.setState({ changeOrderModalIsOpen: false })
                        }
                        order={selectedOrder}
                    />

                    <DescriptionModal
                        isOpen={descriptionModalIsOpen}
                        order={selectedOrder}
                        close={() =>
                            this.setState({ descriptionModalIsOpen: false })
                        }
                        onChange={() => onUpdateOrder()}
                    />

                    {/* order details */}
                    <div className="order-details">
                        <div className="flex flex-space-between full mb15">
                            <div />
                            <div className="flex">
                                <div
                                    className={
                                        selectedOrder.status === 'pending'
                                            ? 'flex'
                                            : 'hidden'
                                    }
                                >
                                    <div
                                        className={
                                            selectedOrder.type === CROP_ROTATION
                                                ? 'hidden'
                                                : null
                                        }
                                    >
                                        <button
                                            className="btn3 small flex flex-center-y"
                                            onClick={() => {
                                                if (!selectedOrder.vendor) {
                                                    return window.alert(
                                                        'Please assign a vendor',
                                                    );
                                                } else {
                                                    if (
                                                        selectedOrder.type ===
                                                            INSTALLATION ||
                                                        selectedOrder.type ===
                                                            REVIVE
                                                    ) {
                                                        window.location.href = `${window.location.origin}/${selectedOrder.type}-report?order=${selectedOrder._id}`;
                                                    } else if (
                                                        selectedOrder.type ===
                                                            FULL_PLAN ||
                                                        selectedOrder.type ===
                                                            ASSISTED_PLAN
                                                    ) {
                                                        window.location.href = `${window.location.origin}/maintenance-report?order=${selectedOrder._id}`;
                                                    } else if (
                                                        selectedOrder.type ===
                                                        INITIAL_PLANTING
                                                    ) {
                                                        window.location.href = `${window.location.origin}/initial-planting-report?order=${selectedOrder._id}`;
                                                    } else if (
                                                        selectedOrder.type ===
                                                        SOIL_ANALYSIS
                                                    ) {
                                                        window.location.href = `${window.location.origin}/soil-analysis-report?order=${selectedOrder._id}`;
                                                    } else {
                                                        this.setState({
                                                            uploadModalIsOpen: true,
                                                        });
                                                    }
                                                }
                                            }}
                                        >
                                            <CloudUploadOutlined />
                                            <span style={{ marginLeft: 8 }}>
                                                Upload Results
                                            </span>
                                        </button>
                                    </div>
                                    <div
                                        className={
                                            selectedOrder.type !==
                                                'installation' &&
                                            selectedOrder.type !== 'revive' &&
                                            selectedOrder.type !== 'misc'
                                                ? 'hidden'
                                                : 'ml15'
                                        }
                                    >
                                        <button
                                            className="btn3 small"
                                            onClick={() =>
                                                this.setState({
                                                    changeOrderModalIsOpen: true,
                                                })
                                            }
                                        >
                                            <EditOutlined />
                                            <span style={{ marginLeft: 8 }}>
                                                Change Order
                                            </span>
                                        </button>
                                    </div>
                                </div>
                                <div
                                    className={
                                        selectedOrder.status === 'complete'
                                            ? 'flex'
                                            : 'hidden'
                                    }
                                >
                                    <button
                                        className={
                                            selectedOrder.type === FULL_PLAN ||
                                            selectedOrder.type === ASSISTED_PLAN
                                                ? 'btn3 small'
                                                : 'hidden'
                                        }
                                        onClick={() =>
                                            (window.location.href = `${window.location.origin}/maintenance-results?order=${selectedOrder._id}`)
                                        }
                                    >
                                        <CommentOutlined />
                                        <span style={{ marginLeft: 8 }}>
                                            View Note
                                        </span>
                                    </button>
                                </div>
                            </div>
                        </div>
                        {specialRequest && (
                            <div className="card mb15">
                                <h5 className="mb15">Special Request</h5>
                                <p className="m0">
                                    "{specialRequest.description}"
                                </p>
                            </div>
                        )}
                        <div
                            className={`${
                                user.data.type !== 'admin' ? 'hidden' : null
                            } data-detail mb25`}
                        >
                            <label>Vendor</label>
                            <select
                                style={{ marginTop: 'var(--unit2)' }}
                                className="fit-content"
                                disabled={selectedOrder.status === 'complete'}
                                value={
                                    selectedOrder.vendor
                                        ? selectedOrder.vendor._id
                                        : 'none'
                                }
                                onChange={(e) =>
                                    this.props.setVendor(
                                        e.target.value,
                                        'order',
                                    )
                                }
                            >
                                <option value={'none'}>None</option>
                                {users.data &&
                                    users.data
                                        .filter(
                                            (user) =>
                                                (user.type === PARTNER &&
                                                    user.active_vendor) ||
                                                (user.type === GARDENER &&
                                                    user.active_vendor),
                                        )
                                        .map((user, index) => (
                                            <option
                                                value={user._id}
                                                key={index}
                                            >
                                                {`${user.first_name} ${user.last_name}`}
                                            </option>
                                        ))}
                            </select>
                        </div>
                        <div className="data-detail">
                            <label>Order Type</label>
                            <p>{selectedOrder.type}</p>
                        </div>
                        <div className="data-detail">
                            <label>Status</label>
                            <p className="flex flex-center-y">
                                {selectedOrder.status}
                                {selectedOrder.status === 'complete' ? (
                                    <Icon className="text-green1">check</Icon>
                                ) : null}
                            </p>
                        </div>
                        <div className="data-detail">
                            <label>Date/Time</label>
                            <p className="flex flex-space-between flex-center-y">
                                <span>
                                    {moment(selectedOrder.date).format(
                                        'MM/DD/YYYY',
                                    )}
                                    <span
                                        className={
                                            selectedOrder.type === FULL_PLAN ||
                                            selectedOrder.type ===
                                                ASSISTED_PLAN ||
                                            selectedOrder.type ===
                                                INITIAL_PLANTING ||
                                            selectedOrder.type === SOIL_ANALYSIS
                                                ? 'hidden'
                                                : ''
                                        }
                                    >
                                        {' '}
                                        @{' '}
                                        {moment(
                                            selectedOrder.time,
                                            `HH:mm:ss`,
                                        ).format(`h:mm A`)}
                                    </span>
                                </span>
                                <EditOutlined
                                    className={
                                        selectedOrder.status === 'complete'
                                            ? 'hidden'
                                            : 'text-purpleB pointer ml15'
                                    }
                                    onClick={() =>
                                        this.setState({
                                            dateTimeModalIsOpen: true,
                                        })
                                    }
                                />
                            </p>
                        </div>
                        <div className="data-detail">
                            <label>Customer</label>
                            <p>
                                {`${selectedOrder.customer.first_name} ${selectedOrder.customer.last_name}`}
                                <br />
                                {selectedOrder.customer.address}
                                {selectedOrder.customer.unit
                                    ? ` #${selectedOrder.customer.unit}`
                                    : ''}
                                , {selectedOrder.customer.city}{' '}
                                {selectedOrder.customer.state}{' '}
                                {selectedOrder.customer.zip_code}
                                <br />
                                {selectedOrder.customer.email}
                                <br />
                                {formatNumber(
                                    selectedOrder.customer.phone_number,
                                )}
                            </p>
                        </div>
                        <div
                            className={
                                user.data.type === 'customer' ? 'hidden' : null
                            }
                        >
                            <label>Description</label>
                            <div className="flex flex-space-between flex-center-y">
                                <div className="pr15">
                                    <p className="mb15">
                                        {selectedOrder.description}
                                    </p>
                                </div>
                                <EditOutlined
                                    className={
                                        selectedOrder.status === 'complete' ||
                                        user.data.type === 'customer'
                                            ? 'hidden'
                                            : 'text-purpleB pointer'
                                    }
                                    onClick={() =>
                                        this.setState({
                                            descriptionModalIsOpen: true,
                                        })
                                    }
                                />
                            </div>
                        </div>
                        <div
                            className={`${
                                user.data.type === 'customer' ? 'hidden' : null
                            } data-detail mt10`}
                        >
                            <label>Payout</label>
                            <div>
                                <div className="pr15">
                                    <span>
                                        {this.calculatePayout(
                                            changeOrders.data,
                                        )}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else {
            return <></>;
        }
    }
}

function mapStateToProps(state) {
    return {
        user: state.user,
        users: state.users,
        changeOrders: state.changeOrder,
        beds: state.bed,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            createOrder,
            updateOrder,
        },
        dispatch,
    );
}

OrderDetails = connect(mapStateToProps, mapDispatchToProps)(OrderDetails);

export default OrderDetails;
